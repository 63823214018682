import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language"; // Globe icon
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Dropdown arrow
import { LanguageContext } from "../services/LanguageContext";

const IntroductionPageLanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { changeLanguage } = useContext(LanguageContext);

  const currentLanguage = localStorage.getItem("language");

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="contained"
        startIcon={<LanguageIcon />}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          backgroundColor: "#F26456", // Red background
          borderRadius: "30px", // Rounded corners
          color: "white", // White text
          padding: "5px 15px", // Padding to make it look like the image
          fontWeight: "bold", // Bold text for "EN"
          "&:hover": {
            backgroundColor: "#F26456", // Prevent hover color change
          },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Center the text horizontally and vertically
          flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
        }}
      >
        {i18n.language.toUpperCase()} {/* Show current language */}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => changeLanguage("en")}>English</MenuItem>
        <MenuItem onClick={() => changeLanguage("ar")}>العربية</MenuItem>
      </Menu>
    </div>
  );
};

export default IntroductionPageLanguageSwitcher;
