import React, { useState, useRef } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  Grid,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { generateOTP, verifyOTP } from "../services/api";
import { useTranslation } from "react-i18next";

const VerifyOtpPage = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const [otp, setOtp] = useState(Array(6).fill("")); // Initialize state for each digit
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = React.useState(false); // Initial loading state
  const location = useLocation();
  const email = location.state?.email;
  const isFirstTimeSettingPassword = location.state?.isFirstTimeSettingPassword;
  console.log(isFirstTimeSettingPassword);
  console.log(email);
  const navigate = useNavigate();

  // Use useRef to get references to the input elements
  const inputsRef = useRef([]);

  const handleOtpChange = (digit, index) => {
    const newOtp = [...otp];
    newOtp[index] = digit;
    setOtp(newOtp);

    // Determine if the digit was deleted
    if (digit === "") {
      // Move focus to the previous input field
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    } else {
      // Move focus to the next input field
      if (index < 5) {
        inputsRef.current[index + 1].focus();
      } else {
        if (newOtp.every((digit) => digit !== "")) {
          // TODO: Submit the form programmatically
          console.debug("OTP Entered:", newOtp.join(""));
        }
      }
    }
  };

  const validate = () => {
    // Check if the phone number length is less than 10 characters
    if (otp.join("").length < 6) {
      // If so, set an error message indicating the phone number is too short
      setError("Please enter a valid OTP");
      // Return false to indicate validation failed
      return false;
    }

    // If no errors were found, clear any existing error message
    setError("");

    // Return true to indicate validation passed
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Clear any previous error messages
    setError("");

    // Clear any previous success messages
    setSuccess("");

    // Validate the input fields before proceeding
    let valid = validate();

    if (!valid) {
      setLoading(false);
      return;
    }

    try {
      if (/^\d{6}$/.test(otp.join(""))) {
        const response = await verifyOTP(email, otp.join(""));
        console.log(response);
        navigate("/set-password", {
          state: {
            email: email,
            key: response.message.key,
            isFirstTimeSettingPassword: isFirstTimeSettingPassword,
          },
        });

        setSuccess("OTP verified successfully!");
      } else {
        throw new Error("Invalid OTP");
      }
    } catch (error) {
      setError("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const goBackHome = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleResendOtp = async (event) => {
    event.preventDefault();

    // Indicate that the login process has started
    setLoading(true);

    // Clear any previous error messages
    setError("");

    // Clear any previous success messages
    setSuccess("");

    try {
      await generateOTP(email);
      setSuccess("OTP sent successfully.");
    } catch (error) {
      setError("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography
          variant="h5"
          gutterBottom
          alignSelf={"start"}
          sx={{
            position: "absolute",
            top: "30px", // Adjust the vertical distance from the top
            left: currentLanguage === "en" ? "16px" : "0px", // Adjust the horizontal distance from the right
            right: currentLanguage === "ar" ? "16px" : "0px",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon></ChevronLeftIcon>
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {t("verifyOtp")}
        </Typography>

        <Box width="100%">
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>

        <Box component="form" width="100%" mt={2} onSubmit={handleSubmit}>
          <Typography variant="subtitle1">
            {" "}
            {t("PleaseEnterTheOtpSentTo")}: {email}
          </Typography>
          {currentLanguage === "en" ? (
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {Array.from({ length: 6 }, (_, index) => (
                <Grid item xs={2} mt={5} mb={3} key={index}>
                  <input
                    ref={(el) => (inputsRef.current[index] = el)} // Assign ref to each input element
                    type="text"
                    maxLength={1}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "left",
                      borderRadius: "10%",
                      backgroundColor: "#EBEBEB",
                      border: "none",
                      outline: "none",
                      fontSize: "2em",
                      direction: "ltr",
                    }}
                    value={otp[index]}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="row-reverse"
            >
              {Array.from({ length: 6 }, (_, index) => (
                <Grid item xs={2} mt={5} mb={3} key={index}>
                  <input
                    ref={(el) => (inputsRef.current[index] = el)} // Assign ref to each input element
                    type="text"
                    maxLength={1}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "left",
                      borderRadius: "10%",
                      backgroundColor: "#EBEBEB",
                      border: "none",
                      outline: "none",
                      fontSize: "2em",
                      direction: "ltr",
                    }}
                    value={otp[index]}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <LoadingButton
            fullWidth
            variant="text"
            onClick={handleResendOtp}
            loading={loading}
            loadingPosition="start"
            startIcon={<span></span>}
          >
            {t("resendOtp")}
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ my: 2 }}
            loading={loading}
            loadingPosition="start"
            startIcon={
              <CheckCircleOutlineOutlinedIcon
                sx={{
                  paddingX: "5px",
                }}
              />
            }
          >
            {t("verifyOtp")}
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyOtpPage;
