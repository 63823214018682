import { useEffect, useState } from "react";
import { getEventDetails } from "../services/guest";

const useFetchEventDetails = (id) => {
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoading(true);
        const data = await getEventDetails(id);
        setResults(data);
      } catch (error) {
        console.error("error fetching event details", error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchEventDetails();
  }, [id]);
  return { results, loading, error };
};
export default useFetchEventDetails;
