import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LoginIcon from "@mui/icons-material/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { loginResponse } from "../store/userSlice";
import { useTranslation } from "react-i18next";
// import styles from "./BookingRegistrationPage.module.css";
import EmailIcon from "@mui/icons-material/Email";
import IntroductionPageLanguageSwitcher from "../components/IntroductionPageLanguageSwitcher";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const LoginPage = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate between routes

  // is logged in new function
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useSelector(
    (state) => state.user.customer_data?.message?.message
  );

  const currentLanguage = localStorage?.getItem("language");
  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    }
  }, [user]);
  console.log(isLoggedIn);

  const location = useLocation();
  const data = location.state?.redirect;
  console.log(data);
  // check is user is logged in
  useEffect(() => {
    // Directly check if the user is already logged in
    if (isLoggedIn) {
      // Redirect to home screen if logged in
      navigate("/");
    } else {
      // localStorage.clear();
    }
  }, [navigate, isLoggedIn, data]); // Add navigate as a dependency

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [email, setEmail] = useState(""); // Updated state for email
  const [password, setPassword] = useState(""); // Initial password state
  const [loading, setLoading] = useState(false); // Initial loading state

  const [error, setError] = useState(""); // State to toggle error message
  const [success, setSuccess] = useState(""); // State to toggle success message

  // Function to toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  // Prevent text selection on mouse down to avoid changing input type
  const handleMouseDownPassword = (event) => event.preventDefault();

  // Function to navigate back to home page
  const goBackHome = () => {
    navigate("/");
  };

  // Set the page title upon page load
  useEffect(() => {
    document.title = "YallaFalla | Login"; // Update document title for SEO and usability
  }, []);

  const validate = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address");
      return false;
    }

    if (password.length < 3) {
      setError("Please, enter your account's password");
      return false;
    }

    setError("");
    return true;
  };

  const dispatch = useDispatch();
  const handleLogin = async (event) => {
    setLoading(true);
    setError("");
    setSuccess("");

    let valid = validate();
    if (!valid) {
      setLoading(false);
      return;
    }

    try {
      const result = await dispatch(
        loginResponse({ email: email, password: password })
      );
      if (loginResponse.fulfilled.match(result)) {
        setSuccess("You have been logged in successfully");
        navigate(data);
      } else {
        setError(
          result.payload || "Invalid login credentials. Please try again."
        );
      }
    } catch (error) {
      console.error(error.message);
      setError("Invalid login credentials. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // const handleLogout = () => {
  //   localStorage.clear();
  //   setIsLoggedIn(false);
  //   navigate("/");
  // };

  // translation
  const { t } = useTranslation();
  console.log(currentLanguage);

  return (
    <Container maxWidth="xs">
      {" "}
      {/* Container to center content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        minHeight="80vh"
        position="relative" // Set the position relative to use absolute positioning for the language switcher
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            gutterBottom
            alignSelf={"start"}
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton aria-label="back" onClick={goBackHome}>
              {currentLanguage === "ar" ? (
                <ChevronRightIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <ChevronLeftIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              )}
            </IconButton>
            {t("LoginAsCustomer")}
          </Typography>
          <Box>
            <IntroductionPageLanguageSwitcher />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>

        <Box
          component="form"
          onSubmit={handleLogin}
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {" "}
          {/* Form container */}
          {/* email */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {t("Email")}
            </label>
            <TextField
              fullWidth
              required
              value={email} // Using email state
              onChange={(event) => setEmail(event.target.value)}
              type="email"
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon sx={{ color: "#F26557" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* Password */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {t("Password")}
            </label>
            <TextField
              fullWidth
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              type={showPassword ? "text" : "password"}
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#F26557" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <LoadingButton
            fullWidth
            size="large"
            onClick={handleLogin}
            loading={loading}
            variant="contained"
            loadingPosition="start"
            startIcon={<LoginIcon />}
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              marginY: "20px",
            }}
          >
            <span>{t("Login")}</span>
          </LoadingButton>
          <Typography variant="body2" align="center">
            {t("DontHaveAnAcount")} ?
            <Link
              style={{
                color: "#F26456",
                textDecoration: "none",
              }}
              to="/register"
            >
              {" "}
              {t("Registration")}
            </Link>{" "}
            {/* Registration link */}
          </Typography>
          <Box>
            <Typography variant="body2" align="center">
              <Link
                style={{
                  color: "#F26456",
                  textDecoration: "none",
                }}
                to="/forget-password-enter-your-email"
              >
                {" "}
                {t("ResetPassword")}
              </Link>{" "}
              {/* Registration link */}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
