import React, { useEffect, useState } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RatingsReviews({ ratingObject, activityId }) {
  const { t } = useTranslation();
  const [isRating, setIsRating] = useState();
  const location = useLocation(); // Get the current location object

  useEffect(() => {
    // Check if the URL contains the word "rating"
    if (location.pathname.includes("rating")) {
      setIsRating(true);
    } else {
      setIsRating(false);
    }
  }, [location.pathname]);

  if (!ratingObject) {
    return <div>Loading...</div>; // or any loading indicator you prefer
  }
  console.log(location.pathname.includes("rating"));

  const currentLanguage = localStorage.getItem("language");

  const {
    1: oneStar,
    2: twoStars,
    3: threeStars,
    4: fourStars,
    5: fiveStars,
    total,
    average,
  } = ratingObject;

  const ratingsData = {
    totalReviews: total,
    averageRating: average,
    ratings: [
      { stars: 5, percentage: (fiveStars / total) * 100 || 0 }, // Prevent division by zero
      { stars: 4, percentage: (fourStars / total) * 100 || 0 },
      { stars: 3, percentage: (threeStars / total) * 100 || 0 },
      { stars: 2, percentage: (twoStars / total) * 100 || 0 },
      { stars: 1, percentage: (oneStar / total) * 100 || 0 },
    ],
  };

  console.log(ratingsData);

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "500px",
        minWidth: "300px",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {t("RatingAndReviews")}
        </Typography>
      </Box>

      {/* Star Rating Summary */}
      <Box
        sx={{
          display: "flex",
          flexDirection: currentLanguage === "ar" ? "row" : "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {!isRating && (
            <Link
              to={`/${activityId}/rating`}
              variant="body2"
              style={{
                color: "rgba(84,84,84,77%)",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              {t("SeeAll")}
            </Link>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {ratingsData.averageRating.toFixed(1)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
              }}
            >
              {[...Array(5)].map((_, index) =>
                index < Math.floor(ratingsData.averageRating) ? (
                  <StarIcon
                    key={index}
                    sx={{ color: "#FFD700", fontSize: "18px" }}
                  />
                ) : (
                  <StarBorderIcon
                    key={index}
                    sx={{ color: "#FFD700", fontSize: "18px" }}
                  />
                )
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#757575", fontSize: "10px" }}
            >{`${ratingsData.totalReviews} ${t("Reviews")}`}</Typography>
          </Box>
        </Box>

        {/* Star Rating Breakdown */}
        <Box sx={{ mt: 2 }}>
          {ratingsData.ratings.map((rating) => (
            <Box
              key={rating.stars}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
              }}
            >
              <Typography sx={{ minWidth: 20 }}>{rating.stars}</Typography>
              {currentLanguage === "en" ? (
                <LinearProgress
                  variant="determinate"
                  value={rating.percentage}
                  sx={{
                    height: 8,
                    borderRadius: 5,
                    backgroundColor: "#f0f0f0",
                    width: "150px",
                    mx: 2,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FFB400", // Star color
                    },
                  }}
                />
              ) : (
                <LinearProgress
                  variant="determinate"
                  value={rating.percentage}
                  sx={{
                    height: 8,
                    borderRadius: 5,
                    backgroundColor: "#f0f0f0",
                    width: "150px",
                    mx: 2,
                    direction: "rtl", // Add RTL for Arabic
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FFB400", // Star color
                      transform: "translateX(100%) scaleX(-1)", // Reverse the progress direction for RTL
                    },
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default RatingsReviews;
