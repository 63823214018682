import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "../services/api";
const BASE_URL = process.env.REACT_APP_API_URL;

export const loginResponse = createAsyncThunk(
  "user/loginResponse",
  async ({ email, password }, thunkAPI) => {
    const { rejectedWithValue } = thunkAPI;

    try {
      // Construct the payload
      const payload = {
        email: email,
        password: password,
      };

      // Make the POST request to the Frappe login API
      const response = await apiClient.post(
        `${BASE_URL}/api/method/yallafalla.auth.api.customer_login`,
        payload
      );

      // On success, extract the token, encode it, and store it in localStorage
      if (response.status === 200) {
        const tokenEncoded = btoa(response.data.message.token); // Encode the token in base64
        localStorage.setItem("authToken", tokenEncoded); // Store the encoded token in localStorage
        localStorage.setItem(
          "customer_name",
          response?.data?.message.customer_data?.customer_name
        );
        localStorage.setItem(
          "email",
          response?.data?.message.customer_data?.user
        );
        localStorage.setItem(
          "country",
          response?.data?.message.customer_data?.country
        ); // Store the encoded token in localStorage
        localStorage.setItem(
          "first_time_booking",
          response?.data?.message.customer_data?.first_time_booking
        );
        localStorage.setItem("language", "ar");
        localStorage.setItem(
          "customer_image",
          response?.data?.message?.customer_data?.user_image
        );
        localStorage.setItem(
          "mobile_number",
          response?.data?.message?.customer_data?.mobile_number
        );
        localStorage.setItem(
          "mobile_number",
          response?.data?.message?.customer_data?.mobile_number
        );
        localStorage.setItem(
          "nid",
          response?.data?.message?.customer_data?.national_id
        );
        localStorage.setItem(
          "city",
          response?.data?.message?.customer_data?.city
        );
        localStorage.setItem(
          "age",
          response?.data?.message?.customer_data?.age
        );
        localStorage.setItem(
          "gender",
          response?.data?.message?.customer_data?.gender
        );

        console.log(
          "response.data",
          response.data.customer_data?.customer_name
        );
        console.log("response.data", response.data.message);
        console.log("tokenEncoded", tokenEncoded);

        return response.data;
      }

      // If there's an issue, throw an error
      throw new Error("Failed to login to the application");
    } catch (error) {
      console.error("Error fetching login response: ", error);
      return rejectedWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    customer_data: {},
    token: localStorage.getItem("authToken") || null,
    error: null,
    language: "ar",
  },
  // reducer to take a boolean and replace all the initial state data with null if false
  reducers: {
    logout: (state, action) => {
      if (action.payload === false) {
        localStorage.clear();
        state.token = null;
        state.customer_data = {};
        state.error = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginResponse.pending, (state) => {
        state.error = null;
      })
      .addCase(loginResponse.fulfilled, (state, action) => {
        state.customer_data = action.payload;
        state.token = action.payload.message.token;
      })
      .addCase(loginResponse.rejected, (state, action) => {
        state.error = action.payload; // Store the error message
      });
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;

// const initialState = {
//   response: {},
//   error: null,
// };

// const userSlice = createSlice({
//   name: "user",
//   initialState,
//   reducers: {
//     setLoginResponse: (state, action) => {
//       state.response = action.payload;
//     },
//     setError: (state, action) => {
//       state.error = action.payload;
//     },
//   },
// });

// export const { setLoginResponse, setError } = userSlice.actions;
// export default userSlice.reducer;
