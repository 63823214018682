import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// import LoginIcon from "@mui/icons-material/Login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { setPassword } from "../services/api";
import { loginResponse } from "../store/userSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChangePassword } from "../services/guest";

const SetPassword = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate(); // Hook to programmatically navigate between routes
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showRePassword, setShowRePassword] = useState(false); // State to toggle password visibility

  const [password, setPass] = useState(""); // Initial password state
  const [re_password, setRePass] = useState(""); // Initial password state

  const [loading, setLoading] = React.useState(false); // Initial loading state
  const [currentPassword, setCurrentPassword] = useState(""); // For current password field
  const [newPassword, setNewPassword] = useState(""); // For new password field
  const [rePassword, setRePassword] = useState(""); // For confirm password field
  const location = useLocation();
  const email = location.state?.email;
  const isFirstTimeSettingPassword = location.state?.isFirstTimeSettingPassword;
  console.log(isFirstTimeSettingPassword);
  console.log(email);
  const key = location.state?.key;

  const [error, setError] = useState(""); // State to toggle error message
  const [success, setSuccess] = useState(""); // State to toggle success message

  // Function to toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowRePassword = () => setShowRePassword(!showRePassword);

  // Prevent text selection on mouse down to avoid changing input type
  const handleMouseDownPassword = (event) => event.preventDefault();

  // Function to navigate back to home page
  const goBackHome = () => {
    navigate(-1);
  };

  const dispatch = useDispatch();

  // Set the page title upon page load
  useEffect(() => {
    document.title = "YallaFalla | Set Password"; // Update document title for SEO and usability
  }, []);

  const validate = () => {
    console.log("pass", password);
    console.log("re_password", re_password);
    console.log("rePassword", rePassword);
    if (isFirstTimeSettingPassword === false && newPassword !== rePassword) {
      setError("Passwords do not match");
      return false;
    }
    if (isFirstTimeSettingPassword === true && password !== re_password) {
      setError("Passwords do not match");
      return false;
    }
    if (isFirstTimeSettingPassword === false && newPassword.length < 3) {
      setError("Please, enter a valid password");
      return false;
    }
    if (isFirstTimeSettingPassword === true && password.length < 3) {
      setError("Please, enter a valid password");
      return false;
    }
    setError("");
    return true;
  };

  const handleSetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      if (isFirstTimeSettingPassword === false) {
        // Call setPassword if it's the first time setting a password
        await ChangePassword(currentPassword, newPassword);
        setSuccess("Password Changed Successfully");
      } else {
        // Call ChangePassword if changing an existing password
        await setPassword(email, key, newPassword);
        setSuccess("Password Set Successfully");
      }

      // Dispatch login if applicable
      const result = dispatch(
        loginResponse({ email: email, password: newPassword })
      );
      if (loginResponse.fulfilled.match(result)) {
        setSuccess("You have been logged in successfully");
      }

      navigate("/");
    } catch (error) {
      console.error(error.message);
      setError("Couldn't set password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      {" "}
      {/* Container to center content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh"
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            position: "absolute",
            top: "30px", // Adjust the vertical distance from the top
            left: currentLanguage === "en" ? "16px" : "0px", // Adjust the horizontal distance from the right
            right: currentLanguage === "ar" ? "16px" : "0px",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon></ChevronLeftIcon>
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {t("CreatePassword")}
        </Typography>
        <Box sx={{ width: "100%" }}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
        <Box
          component="form"
          onSubmit={handleSetPassword}
          width="100%"
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            marginTop: "100px",
          }}
        >
          {" "}
          {/* Form container */}
          {/* current password if isFirstTimeSettingPassword is false */}
          {isFirstTimeSettingPassword === false && (
            <Box>
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: "semibold",
                  marginLeft: "20px",
                  marginBottom: "-10px",
                }}
              >
                {t("CurrentPassword")}
              </label>
              <TextField
                placeholder={t("EnterYourPassword")}
                fullWidth
                required
                value={currentPassword}
                onChange={(event) => {
                  setCurrentPassword(event.target.value);
                }}
                onCopy={(event) => {
                  event.preventDefault();
                }}
                type={showPassword ? "text" : "password"} // Toggle password visibility based on state
                margin="normal"
                InputProps={{
                  sx: {
                    borderRadius: "50px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showRePassword ? <Visibility /> : <VisibilityOff />}{" "}
                        {/* Icon changes based on password visibility */}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon sx={{ color: "#F26557" }} />{" "}
                      {/* Start adornment icon */}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          {/* password */}
          <Box>
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {isFirstTimeSettingPassword === false
                ? t("NewPassword")
                : t("Password")}
            </label>
            <TextField
              placeholder={t("EnterYourPassword")}
              fullWidth
              required
              value={!isFirstTimeSettingPassword ? newPassword : password}
              onChange={(event) => {
                !isFirstTimeSettingPassword
                  ? setNewPassword(event.target.value)
                  : setPass(event.target.value);
              }}
              onCopy={(event) => {
                event.preventDefault();
              }}
              type={showPassword ? "text" : "password"} // Toggle password visibility based on state
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showRePassword ? <Visibility /> : <VisibilityOff />}{" "}
                      {/* Icon changes based on password visibility */}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#F26557" }} />{" "}
                    {/* Start adornment icon */}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* confirm password */}
          <Box>
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {isFirstTimeSettingPassword === false
                ? t("ConfirmNewPassword")
                : t("ReEnterPassword")}
            </label>
            <TextField
              placeholder={t("EnterYourPasswordAgain")}
              fullWidth
              required
              value={!isFirstTimeSettingPassword ? rePassword : re_password}
              onChange={(event) => {
                !isFirstTimeSettingPassword
                  ? setRePassword(event.target.value)
                  : setRePass(event.target.value);
              }}
              onCopy={(event) => {
                event.preventDefault();
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              type={showRePassword ? "text" : "password"} // Toggle password visibility based on state
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRePassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showRePassword ? <Visibility /> : <VisibilityOff />}{" "}
                      {/* Icon changes based on password visibility */}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "#F26557" }} />{" "}
                    {/* Start adornment icon */}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* submit button */}
          <LoadingButton
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              marginY: "20px",
            }}
            fullWidth
            color="primary"
            size="large"
            onClick={handleSetPassword}
            loading={loading}
            variant="contained"
            loadingPosition="start"
          >
            <span>{t("Submit")}</span>
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default SetPassword;
