import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./BookingRegistrationPage.module.css";
import { useForm } from "react-hook-form";
import useFetchCountries from "../hooks/useFetchCountries";
import useFetchCities from "../hooks/useFetchCities";
import { updateCustomerInfo } from "../services/guest";
import PersonIcon from "@mui/icons-material/Person";
import { MuiTelInput } from "mui-tel-input";
import MaleIcon from "@mui/icons-material/Male";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";

const MyAccountPage = () => {
  const getLocalStorageValue = (key) => {
    const value = localStorage.getItem(key);
    return value === "null" || value === null ? "" : value;
  };
  const [phoneValue, setPhoneValue] = useState(
    getLocalStorageValue("mobile_number")
  );
  const [profileImage, setProfileImage] = useState(null);
  const [preview, setPreview] = useState(
    getLocalStorageValue("customer_image")
  );
  const [country, setCountry] = useState(getLocalStorageValue("country"));
  console.log(getLocalStorageValue("country"));
  const [city, setCity] = useState(getLocalStorageValue("city"));
  const [gender, setGender] = useState(getLocalStorageValue("gender"));
  const [age, setAge] = useState(getLocalStorageValue("age"));
  const [customerNiId, setCustomerNiId] = useState(getLocalStorageValue("nid"));
  const [reload, setReload] = useState(false);

  useEffect(() => {
    // Trigger reload by updating the state
    setReload(true);

    // Optionally, you can clear any related states or fetch fresh data here
    return () => {
      // Cleanup logic if needed
      setReload(false); // Reset reload state on unmount
    };
  }, []);

  useEffect(() => {
    if (reload) {
      // Your reload logic here (e.g., reset form, refetch data)
      console.log("Page reloaded!");
    }
  }, [reload]);

  const customerEmail = getLocalStorageValue("email");
  const fullName = getLocalStorageValue("customer_name");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      country: country,
      city: city,
      age: age,
      id: customerNiId,
    },
  });

  const { countries } = useFetchCountries();
  const { cities } = useFetchCities(country);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  useEffect(() => {
    document.title = "YallaFalla | My Account";
    if (profileImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(profileImage);
    }
  }, [profileImage]);

  // Handle form submission

  const goBackHome = () => {
    navigate(-1);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image")) {
      setProfileImage(file); // Store file directly
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // For preview only
      };
      reader.readAsDataURL(file);
    } else {
      setProfileImage(null); // Reset if invalid file
      setPreview(""); // Clear preview if invalid file
    }
  };

  const handleAgeChange = (e) => {
    setAge(e.target.value);
    setValue("age", e.target.value);
  };

  // Updated onChange handler for the national ID field to use event.target.value
  const handleNiIdChange = (e) => {
    setCustomerNiId(e.target.value);
    setValue("id", e.target.value);
  };

  const onSubmit = async (data) => {
    console.log("data.country: ", data.country, "country :", country);
    console.log("data.country: ", data.city, "country :", city);
    try {
      const updatedData = {
        nid: customerNiId || data.id,
        first_name: fullName,
        city: data.city || city,
        country: data.country || country,
        gender: data.gender || gender,
        age: data.age || age,
        mobile_number: phoneValue,
        user_image: profileImage,
      };
      console.log("Country being sent:", data.country || country);

      const response = await updateCustomerInfo(
        updatedData.nid,
        updatedData.city,
        updatedData.country,
        updatedData.gender,
        updatedData.age,
        updatedData.mobile_number,
        updatedData.user_image,
        updatedData.first_name
      );

      console.log("API Response:", response);
      console.log(profileImage);

      // Save updated data to localStorage
      localStorage.setItem("customer_name", fullName);
      localStorage.setItem("mobile_number", phoneValue);
      localStorage.setItem("country", data.country || country);
      localStorage.setItem("city", data.city || city);
      localStorage.setItem("gender", data.gender || gender);
      localStorage.setItem("age", data.age || age); // Update age
      localStorage.setItem("nid", data.id || customerNiId); // Update national ID
      if (preview) {
        localStorage.setItem("customer_image", preview);
      }

      navigate("/logout"); // Navigate after successful update (optional)
    } catch (error) {
      console.error("Error updating customer info:", error);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("profile-image-upload").click();
  };

  return (
    <div style={{ margin: "50px 10px" }}>
      <Box
        sx={{
          width: "100%",
          padding: "20px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("MyAccount")}
        </Typography>
      </Box>

      {/* profile pic changer  */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {preview ? (
          <img
            src={preview}
            alt="Profile Preview"
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "10px",
            }}
          />
        ) : (
          <div
            style={{
              width: "150px",
              height: "150px",
              backgroundColor: "#F2F2F2",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <PersonIcon sx={{ fontSize: "50px", color: "#757575" }} />
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="profile-image-upload"
        />
        <button
          onClick={handleButtonClick}
          type="button"
          style={{
            backgroundColor: "#F26557",
            color: "white",
            padding: "8px 15px",
            borderRadius: "25px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {t("ChangeProfilePicture")}
        </button>
      </Box>

      {/* Form */}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {/* Full Name */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          {currentLanguage === "en" ? (
            <Box>
              <label>
                <PersonIcon sx={{ color: "rgba(242,101,87,76%)" }} />
              </label>
              <input
                defaultValue={fullName} // Use defaultValue instead of value
                // onChange={(e) => setFullName(e.target.value)}
                type="text"
                name="fullName"
                placeholder="Full Name"
                {...register("fullName", {
                  required: { value: true, message: t("FullNameIsRequired") },
                })}
                readOnly
              />
            </Box>
          ) : (
            <Box className={styles.formGroup}>
              <input
                value={fullName} // Bind input to fullName state
                // onChange={(e) => setFullName(e.target.value)}
                defaultValue={fullName}
                type="text"
                name="fullName"
                style={{ textAlign: "right" }} // Text alignment for Arabic
                placeholder="Full Name"
                {...register("fullName", {
                  required: { value: true, message: t("FullNameIsRequired") },
                })}
                readOnly
              />
              <label>
                <PersonIcon sx={{ color: "rgba(242,101,87,76%)" }} />
              </label>
            </Box>
          )}
          {errors.fullName && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.fullName.message}
              </span>
            </Box>
          )}
        </div>

        {/* Phone */}
        <div style={{ width: "100%" }}>
          <MuiTelInput
            InputProps={{
              sx: {
                borderRadius: "50px",
                border: "0px",
                borderColor: errors.phone ? "red" : "",
                paddingY: "8px",
              },
            }}
            defaultCountry="SA"
            fullWidth
            margin="normal"
            value={phoneValue}
            onChange={(value) => setPhoneValue(value)}
          />
          {errors.phone && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                  padding: "5px 10px",
                }}
              >
                {errors.phone.message}
              </span>
            </Box>
          )}
        </div>

        {/* Country */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <select
            name="country"
            className={
              currentLanguage === "ar"
                ? styles.customSelectAr
                : styles.customSelect
            } // Use the custom class
            style={{
              borderColor: errors.country ? "red" : "",
              backgroundColor: "transparent",
            }}
            {...register("country", {
              required: {
                value: !country, // Validate only if 'country' is empty
                message: t("CountryIsRequired"),
              },
            })}
            value={country}
            onChange={(e) => {
              setCountry(e.target.value); // Update country state
              setValue("country", e.target.value); // Update react-hook-form state
            }}
          >
            <option value="" disabled hidden>
              {t("Country")}
            </option>
            {countries.map((country) => (
              <option key={country.country_name} value={country.name}>
                {country.country_name}
              </option>
            ))}
          </select>
          {errors.country && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.country.message}
              </span>
            </Box>
          )}
        </div>

        {/* City and Age */}
        <div className={styles.formRow}>
          <div
            className={
              currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
            }
            style={{ flex: "0 0 60%" }}
          >
            <select
              className={
                currentLanguage === "ar"
                  ? styles.customSelectAr
                  : styles.customSelect
              }
              name="city"
              style={{
                borderColor: errors.city ? "red" : "",
                backgroundColor: "transparent",
              }}
              {...register("city", {
                required: {
                  value: !city, // Validate only if 'city' is empty
                  message: t("CityIsRequired"),
                },
              })}
              value={city}
              onChange={(e) => {
                setCity(e.target.value); // Update city state
                setValue("city", e.target.value); // Update react-hook-form state
              }}
            >
              <option value="" disabled hidden>
                {t("City")}
              </option>
              {cities.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.city_name}
                </option>
              ))}
            </select>
            {errors.city && (
              <Box
                sx={{
                  padding: "5px 0px 0px 20px",
                }}
              >
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {errors.city.message}
                </span>
              </Box>
            )}
          </div>

          <div
            className={
              currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
            }
            style={{ flex: "0 0 35%" }}
          >
            <input
              style={{
                borderColor: errors.age ? "red" : "",
              }}
              type="number"
              name="age"
              placeholder={t("Age")}
              defaultValue={age} // Bind to age state
              onChange={(e) => {
                setAge(e.target.value);
              }}
              {...register("age", {
                required: { value: true, message: t("AgeIsRequired") },
              })}
            />
            {errors.age && (
              <Box
                sx={{
                  padding: "5px 0px 0px 20px",
                }}
              >
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {errors.age.message}
                </span>
              </Box>
            )}
          </div>
        </div>

        {/* Gender */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <label htmlFor="gender">
            <MaleIcon sx={{ color: "rgba(242,101,87,76%)" }} />
          </label>
          <select
            className={
              currentLanguage === "ar"
                ? styles.customSelectAr
                : styles.customSelect
            }
            name="gender"
            style={{
              borderColor: errors.gender ? "red" : "",
              backgroundColor: "transparent",
            }}
            {...register("gender", {
              required: { value: true, message: t("GenderIsRequired") },
            })}
            onChange={(e) => {
              setGender(e.target.value); // Update gender state
              setValue("gender", e.target.value); // Update react-hook-form value
            }}
            value={gender}
          >
            <option value="" disabled hidden>
              {t("Gender")}
            </option>
            <option value="male">{t("Male")}</option>
            <option value="female">{t("Female")}</option>
            <option value="Prefer not to say">{t("PreferNotToSay")}</option>
          </select>
          {errors.gender && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.gender.message}
              </span>
            </Box>
          )}
        </div>

        {/* National ID */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <label>
            <BadgeIcon sx={{ color: "rgba(242,101,87,76%)" }} />
          </label>
          <input
            style={{
              borderColor: errors.id ? "red" : "",
            }}
            type="text"
            name="id"
            placeholder={t("NationalID")}
            defaultValue={customerNiId} // Bind to customerNiId state
            onChange={(e) => {
              setCustomerNiId(e.target.value);
            }}
            {...register("id", {
              required: { value: true, message: t("NationalIDIsRequired") },
            })}
          />
          {errors.id && (
            <Box
              sx={{
                padding: "5px 0px 0px 20px",
              }}
            >
              <span
                style={{
                  color: "red",
                }}
              >
                {errors.id.message}
              </span>
            </Box>
          )}
        </div>

        {/* Email (Read-only) */}
        <div
          className={
            currentLanguage === "ar" ? styles.formGroupAr : styles.formGroup
          }
        >
          <label>
            <EmailIcon sx={{ color: "rgba(242,101,87,76%)" }} />
          </label>
          <input
            type="email"
            name="email"
            value={customerEmail}
            readOnly
            placeholder={t("Email")}
            style={{
              backgroundColor: "#f0f0f0", // Light gray background
              color: "#a8a8a8", // Gray text color
              border: "1px solid #ccc", // Light border color
              cursor: "not-allowed", // Disabled cursor style
            }}
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className={styles.submitButton}>
          {t("SaveChanges")}
        </button>
      </form>
    </div>
  );
};

export default MyAccountPage;
