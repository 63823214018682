import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import ErrorIcon from "@mui/icons-material/Error";

const FailedToCancel = ({open,onClose,title}) => {
    const {t} = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px", padding: "20px" } }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        <ErrorIcon sx={{
          fontSize: 60,
          color: "#D9534F",
        }}/>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        {t("FailedToCancelMessage")}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#f26456",
            color: "white",
            fontWeight: "bold",
            borderRadius: "10px",
            padding: "10px 20px",
          }}
          onClick={onClose}
        >
          {t('Done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FailedToCancel