import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import EmailIcon from "@mui/icons-material/Email";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { generateOTP } from "../../services/api";

const ForgetPasswordEmailPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate();
  const goBackHome = () => {
    navigate(-1);
  };

  const handleSetPassword = () => {
    const isFirstTimeSettingPassword = true;
    generateOTP(email);
    navigate("/verify-otp", {
      state: {
        email: email,
        isFirstTimeSettingPassword: isFirstTimeSettingPassword,
      },
    });
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          paddingY: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            gutterBottom
            alignSelf={"start"}
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton aria-label="back" onClick={goBackHome}>
              {currentLanguage === "ar" ? (
                <ChevronRightIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <ChevronLeftIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              )}
            </IconButton>
            {t("LoginAsCustomer")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: "100%",
            fontSize: "16px",
          }}
        >
          <label
            style={{
              fontSize: "18px",
              fontWeight: "semibold",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          >
            {t("Email")}
          </label>
          <TextField
            fullWidth
            required
            value={email} // Using email state
            onChange={(event) => setEmail(event.target.value)}
            type="email"
            margin="normal"
            InputProps={{
              sx: {
                borderRadius: "50px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon sx={{ color: "#F26557" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button
            size="large"
            variant="contained"
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              color: "white",
              width: "100%",
              padding: "10px 70px",
              "&:hover": {
                backgroundColor: "#F26456",
              },
              "&:active": {
                backgroundColor: "#F26456",
              },
              "&:focus": {
                backgroundColor: "#F26456",
              },
            }}
            onClick={handleSetPassword}
          >
            {/* {t("send Otp")} */}
            {t("ChangePassword")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgetPasswordEmailPage;
