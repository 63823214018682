import { useEffect, useState } from "react";
import { getEvents } from "../services/guest";

const useFetchEvents = (specialOffer) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const data = await getEvents(specialOffer);
        setEvents(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, [specialOffer]);
  return { events, loading, error };
};

export default useFetchEvents;
