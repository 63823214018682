import React from "react";
import { Card, CardContent, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CategoryCard({ title, image, name }) {
  const navigate = useNavigate();
  const handleCategoryClick = () => {
    navigate(`/activities?category=${name}`);
  };
  return (
    <Card
      onClick={handleCategoryClick}
      sx={{
        width: 90,
        height: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        borderRadius: 2,
        boxShadow:
          "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Avatar
        alt={title}
        src={image}
        sx={{
          width: 80,
          height: 80,
        }}
      />
      <CardContent sx={{padding:'3px'}}>
        <Typography
          sx={{
            fontSize: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            padding:0
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CategoryCard;
