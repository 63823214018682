import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../store/userSlice";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { LanguageContext } from "../services/LanguageContext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
function ProfilePage() {
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate(); // Hook to programmatically navigate between routes
  const userName = localStorage.getItem("customer_name");
  const dispatch = useDispatch();
  // translation
  const { t } = useTranslation();

  const { changeLanguage } = useContext(LanguageContext);
  const language = localStorage.getItem("language");
  const user_image = localStorage.getItem("customer_image");
  const email = localStorage.getItem("email");

  const handleSetPassword = () => {
    const isFirstTimeSettingPassword = false;
    navigate("/set-password", {
      state: {
        email: email,
        isFirstTimeSettingPassword: isFirstTimeSettingPassword,
      },
    });
  };

  // const handleLanguageChange = (lng) => {
  //   i18n.changeLanguage(lng);
  //   if (lng === "ar") {
  //     document.body.setAttribute("dir", "rtl");
  //     setSelectedLanguage("Arabic");
  //   } else {
  //     document.body.setAttribute("dir", "ltr");
  //     setSelectedLanguage("English");
  //   }
  // };

  const handleLogout = () => {
    dispatch(logout(false));
    navigate("/");
    window.location.reload();
  };

  const handleFavouriteButton = () => {
    navigate("/favourites");
  };
  const handleMyAccountButton = () => {
    navigate("/my-account");
  };

  useEffect(() => {
    document.title = "YallaFalla | Profile";
  }, []);

  const currentLanguage = localStorage.getItem("language");

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        {/* avatar & user name container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar src={user_image} />
          <Typography>{userName}</Typography>
        </Box>
        {/* my Account  */}
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          padding={2}
          bgcolor="#EAEAEA"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                padding: "10px",
                backgroundColor: "rgba(244,122,106,0.37)",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PersonIcon
                sx={{
                  color: "#f26456",
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "#333333",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {t("MyAccount")}
            </Typography>
          </Box>
          {/* arrow icons */}
          <Button
            sx={{
              border: "none",
              color: "black",
            }}
            onClick={handleMyAccountButton}
          >
            {currentLanguage === "en" ? (
              <ArrowForwardIosIcon
                sx={{
                  width: "14px",
                }}
              />
            ) : (
              <ArrowBackIosNewIcon
                sx={{
                  width: "14px",
                }}
              />
            )}
          </Button>
        </Box>
        {/* language */}
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          padding={2}
          bgcolor="#EAEAEA"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                padding: "10px",
                backgroundColor: "rgba(244,122,106,0.37)",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LanguageIcon
                sx={{
                  color: "#f26456",
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "#333333",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {t("Language")}
            </Typography>
          </Box>

          <ButtonGroup
            sx={{
              backgroundColor: "white",
              padding: "8px",
              borderRadius: "10px",
              width: "40%",
            }}
            variant="contained"
            color="primary"
          >
            <Button
              style={{
                backgroundColor: language === "en" ? "#F47A6A" : "transparent",
                color: language === "ar" ? "black" : "",
                borderRadius: "10px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={() => changeLanguage("en")}
            >
              English
            </Button>
            <Button
              style={{
                backgroundColor: language === "ar" ? "#F47A6A" : "transparent",
                color: language === "en" ? "black" : "",
                borderRadius: "10px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={() => changeLanguage("ar")}
            >
              العربيه
            </Button>
          </ButtonGroup>
        </Box>

        {/* fav section  */}
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          padding={2}
          bgcolor="#EAEAEA"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                padding: "10px",
                backgroundColor: "rgba(244,122,106,0.37)",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FavoriteIcon
                sx={{
                  color: "#f26456",
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "#333333",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {t("MyFavourite")}
            </Typography>
          </Box>

          <Button
            sx={{
              border: "none",
              color: "black",
            }}
            onClick={handleFavouriteButton}
          >
            {currentLanguage === "en" ? (
              <ArrowForwardIosIcon
                sx={{
                  width: "14px",
                }}
              />
            ) : (
              <ArrowBackIosNewIcon
                sx={{
                  width: "14px",
                }}
              />
            )}
          </Button>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            alignItems: "start",
            width: "100%",
          }}
        >
          <Typography variant="h5" gutterBottom alignSelf={"start"}>
            <IconButton aria-label="back" onClick={goBackHome}>
              <ChevronLeftIcon></ChevronLeftIcon>
            </IconButton>
            {t("LogoutTitle")}
          </Typography>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <LoadingButton
            size="large"
            onClick={handleLogout}
            sx={{ mt: 2, mb: 2, backgroundColor: "transparent" }}
            loadingPosition="start"
          >
            <span
              style={{
                color: "#f47a6a",
                fontWeight: "bold",
              }}
            >
              {t("LogoutButtonTitle")}
            </span>
          </LoadingButton>
        </Box>
        <Box>
          <Typography variant="body2" align="center">
            <button
              onClick={handleSetPassword}
              style={{
                color: "#F26456",
                textDecoration: "none",
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              {" "}
              {t("ChangePassword")}
            </button>{" "}
            {/* Registration link */}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default ProfilePage;
