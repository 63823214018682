import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Searchbar({ onSearch,searchType="query" }) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };
  const handlekeyPress = (e) => {
     if (e.key === "Enter") {
       if (searchType === "city") {
         onSearch(searchTerm)
       } else {
         navigate(`/activities?query=${searchTerm}`);
       }
     }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
        padding: "10px",
      }}
    >
      <TextField
        variant="outlined"
        placeholder={t("Search")}
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyPress={handlekeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#B0B0B0" }} />
            </InputAdornment>
          ),
          sx: {
            borderRadius: "50px",
            "& fieldset": {
              borderColor: "#E0E0E0",
            },
          },
        }}
        sx={{
          width: { xs: "100%", sm: "100%", md: "400px" },
          "& .MuiOutlinedInput-root": {
            borderRadius: "50px",
            paddingRight: "8px",
            "&.Mui-focused fieldset": {
              borderColor: "#A0A0A0",
            },
          },
        }}
      />
    </Box>
  );
}

export default Searchbar;
