import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

function ActivityStatusCard({ bgColor, title, subTitle }) {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center", // Ensure content is centered vertically
        width: { xs: "100%", sm: "75%", md: "50%", lg: "25%", xl: "150px" }, // Responsive width
        height: { xs: "80px", sm: "180px", md: "200px", lg: "220px" }, // Consistent height for centering
        borderRadius: 5,
        backgroundColor: `${bgColor}`,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          width: "100%",
          height: "100%", // Ensures CardContent takes up the full height of the card
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "14px", sm: "16px" },
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "14px" },
            textAlign: "center",
          }}
        >
          {subTitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ActivityStatusCard;
