// Import axios for making HTTP requests
import axios from "axios";

// Base URL for the application
const BASE_URL = process.env.REACT_APP_API_URL;

// Create an instance of axios with the base URL configured
const apiClient = axios.create({ baseURL: BASE_URL });

// Retrieve the token from localStorage, if it exists
const JWT_token = localStorage.getItem("authToken");
console.log("Token from localStorage:", JWT_token);

let tokenDecoded = null;

// Check if JWT_token exists before decoding
if (JWT_token) {
  try {
    // Decode the token if it is available
    tokenDecoded = atob(JWT_token);
    console.log("Token decoded:", tokenDecoded);
  } catch (error) {
    console.error("Error decoding token:", error);
    tokenDecoded = null; // Set to null if decoding fails
  }
} else {
  console.warn("No token found in localStorage.");
}

// a function that gets the authToken from local storage and decodes it
const getAuthToken = () => {
  const JWT_token = localStorage.getItem("authToken");
  let tokenDecoded = null;

  if (JWT_token) {
    try {
      tokenDecoded = atob(JWT_token);
    } catch (error) {
      console.error("Error decoding token:", error);
      tokenDecoded = null;
    }
  } else {
    console.warn("No token found in localStorage.");
  }

  return tokenDecoded;
};

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // If response is successful, just return it
    return response;
  },
  (error) => {
    // Check for invalid token error in the response
    if (error.response && error.response.data.message === "invalid token") {
      console.warn(
        "Invalid token detected. Clearing localStorage and reloading."
      );

      // Clear localStorage
      localStorage.clear();

      // Reload the page to reset the application state
      window.location.reload();
    }

    // Throw the error for further handling
    return Promise.reject(error);
  }
);

// fetch cities
export const getCities = async (city_name = "") => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_cities?city_name=${city_name}`;
    const response = await apiClient.get(url, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// fetch events
export const getEvents = async (specialOffer = 0) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_events?special_offer=${specialOffer}`;
    const response = await apiClient.get(url, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// fetch countries

export const getCountries = async () => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_countries`;
    const response = await apiClient.get(url, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// fetch banners
export const getBanners = async () => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_bunners`;
    const response = await apiClient.get(url, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// get events categories

export const getEventCategories = async () => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_event_categories`;
    const response = await apiClient.get(url, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// get single category details

export const getCategoryDetails = async (id) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_category_details?category_id=${id}`;
    const response = await apiClient.get(url, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// get single event details

export const getEventDetails = async (id) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_event_details?event_id=${id}`;
    const response = await apiClient.get(url, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// get orders
export const getOrders = async (token, status) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_orders?status=${status}`;
    const response = await apiClient.get(url, {
      headers: {
        "Yalla-Auth-JWT": token,
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// book event
export const bookEvent = async (eventId, date, time, quantity) => {
  const url = `${BASE_URL}/api/method/yallafalla.events.api.book_event`;
  const currentLanguage = localStorage.getItem("language");
  try {
    const payload = {
      event_id: eventId,
      date: date,
      time: time,
      quantity: quantity,
    };
    const response = await apiClient.post(url, payload, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    console.log("response", response.data);
    return response.date;
  } catch (error) {
    console.error("Error booking event", error);
    throw error;
  }
};

// First time booking

export const firstTimeBooking = async (nid, city, gender, age, email) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const payload = {
      nid: nid,
      city: city,
      gender: gender,
      age: age,
      email: email,
    };
    const url = `${BASE_URL}/api/method/yallafalla.events.api.first_time_booking`;
    const response = await apiClient.post(url, payload, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error first time booking", error);
    throw error;
  }
};

export const updateCustomerInfo = async (
  nid,
  city,
  country,
  gender,
  age,
  mobile_number,
  user_image,
  first_name
) => {
  // Initialize FormData
  const formData = new FormData();

  // Append each field to FormData
  formData.append("nid", nid);
  formData.append("city", city);
  formData.append("country", country);
  formData.append("gender", gender);
  formData.append("age", age);
  formData.append("mobile_number", mobile_number);
  formData.append("first_name", first_name);

  // Check if user_image is provided and append it
  if (user_image) {
    formData.append("user_image", user_image);
  }

  const url = `${BASE_URL}/api/method/yallafalla.auth.api.update_customer_info`;
  const currentLanguage = localStorage.getItem("language");
  console.log("token", getAuthToken());

  try {
    const response = await apiClient.post(url, formData, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
        "Content-Type": "multipart/form-data", // Set content type for file upload
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error updating customer info", error);
    throw error;
  }
};

export const cancelOrder = async (orderId) => {
  const currentLanguage = localStorage.getItem("language");

  try {
    const payload = {
      order_id: orderId,
    };
    const url = `${BASE_URL}/api/method/yallafalla.events.api.cancel_order`;
    const response = await apiClient.put(url, payload, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error cancelling order", error);
    throw error;
  }
};

export const eventSearch = async (query, city, category) => {
  const currentLanguage = localStorage.getItem("language");
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.get_events?title=${
      query || ""
    }&category=${category || ""}&city=${city || ""}`;

    const response = await apiClient.get(url, {
      headers: {
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message.data;
  } catch (error) {
    console.error("Error searching events", error);
    throw error;
  }
};

export const eventReview = async (orderId, feedback, rating, feedback_img) => {
  const currentLanguage = localStorage.getItem("language");
  const payload = {
    order_id: orderId,
    feedback: feedback,
    rating: rating,
    feedback_image: feedback_img,
  };
  try {
    const url = `${BASE_URL}/api/method/yallafalla.events.api.submit_feedback`;
    const response = await apiClient.post(url, payload, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    return response.data.message;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

export const eventLike = async (event_id) => {
  const currentLanguage = localStorage.getItem("language");
  const url = `${BASE_URL}/api/method/yallafalla.events.api.like_event`;
  try {
    const response = await apiClient.post(
      url,
      { event_id },
      {
        headers: {
          "Yalla-Auth-JWT": getAuthToken(),
          "Accept-Language": currentLanguage,
        },
      }
    );
    return response.data.message;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

export const ChangePassword = async (currentPassword, newPassword) => {
  const currentLanguage = localStorage.getItem("language");
  const url = `${BASE_URL}/api/method/yallafalla.auth.api.change_password`;
  try {
    const response = await apiClient.post(
      url,
      {
        current_password: currentPassword,
        new_password: newPassword,
      },
      {
        headers: {
          "Yalla-Auth-JWT": getAuthToken(),
          "Accept-Language": currentLanguage,
        },
      }
    );
    console.log(response);
    return response.data.message;
  } catch (error) {
    console.error("Error fetching data", error.message);
    throw error;
  }
};

// chat
export const sendMessage = async (message, receiver) => {
  const currentLanguage = localStorage.getItem("language");
  const url = `${BASE_URL}/api/method/yallafalla.chatting.api.send_message`;

  const payload = {
    message: message,
    receiver: receiver,
  };
  try {
    const response = await apiClient.post(url, payload, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    console.log(response);
    return response.data.message;
  } catch {
    console.error("Error sending message");
  }
};
export const receiveMessage = async (userA, userB) => {
  const currentLanguage = localStorage.getItem("language");
  const url = `${BASE_URL}/api/method/yallafalla.chatting.api.get_messages`;

  const payload = {
    user_a: userA,
    user_b: userB,
  };
  try {
    const response = await apiClient.post(url, payload, {
      headers: {
        "Yalla-Auth-JWT": getAuthToken(),
        "Accept-Language": currentLanguage,
      },
    });
    console.log(response);
    return response.data.message;
  } catch {
    console.error("Error sending message");
  }
};
