import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmCancelPopup = ({ open, onClose, onConfirm }) => {
  const {t} = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px", padding: "20px" } }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <QuestionMarkIcon sx={{ fontSize: 60, color: "#D9534F" }} />
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center", fontWeight:'bold' }}>
        {t("ConfirmCancelMessage")}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{
            backgroundColor: "#7AC78E",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "18px",
            padding: "10px 30px",
            marginLeft: "10px",
          }}
          onClick={onClose}
        >
          {t("CancelButtonText")}
        </Button>
        <Button
          sx={{
            backgroundColor: "#D9534F",
            color: "white",
            fontWeight: "bold",
            fontSize: "18px",
            padding: "10px 30px",
          }}
          onClick={onConfirm}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancelPopup;
