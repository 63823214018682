import { Box, Button, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState } from "react";
import ActivityPageStatusCard from "./ActivityStatusCard";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ActivityHeader({
  name,
  city,
  price,
  description,
  country,
  locationUrl,
  orderName,
  category,
  company,
  status
}) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const createMarkup = (dirty) => {
    return { __html: dirty };
  };
  const {t} = useTranslation();
 

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* title */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#007BFF" }}
            >
              {name}
            </Typography>
          </Box>
          {/* location */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon sx={{ color: "gray" }} />
            <Link
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              to={locationUrl}
            >
              <Typography sx={{ fontSize: "14px", color: "#007BFF" }}>
                {country}, {city}
              </Typography>
            </Link>
          </Box>
          {/* render order id and its icon if it's passed */}
          {orderName ? (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <NewspaperIcon sx={{ color: "gray" }} />
              <Typography sx={{ color: "gray" }}>{orderName}</Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {/* rating */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "4px",
            flexDirection: "column",
            justifyContent:'center'
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "22px", textAlign:'center' }}>
            {price} {t("SAR")}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              marginLeft: "4px",
              fontWeight: "bold",
              color: "red",
              textAlign:"center"
            }}
          >
            {t("perTicket")}
          </Typography>
        </Box>
      </Box>
      {/* description */}
      <Box>
        <Typography
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: showFullDescription ? "none" : 3,
          }}
        >
          <p dangerouslySetInnerHTML={createMarkup(description)} />
        </Typography>
        {description?.split(" ").length > 20 && (
          <Button
            onClick={toggleDescription}
            sx={{ textTransform: "none", color: "#007BFF", marginTop: 1 }}
          >
            {showFullDescription ? t("SeeLess") : t('SeeMore')}
          </Button>
        )}
      </Box>
      {/* details cards */}
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <ActivityPageStatusCard
          title={t("singleActivityCategoryCard")}
          subTitle={category}
          bgColor={"#5b9bd3"}
        />
        <ActivityPageStatusCard
          title={t("singleActivityStatusCard")}
          subTitle={status}
          // bgColor={"#5b9bd3"}
          bgColor={
            status === "Canceled" || status === "ملغي"
              ? "#D9534F"
              : status === "Completed"
              ? "#7AC78E"
              : status === "Coming"
              ? "#7AC78E"
              : status === "Available"
              ? "#7AC78E"
              : "#7AC78E"
          }
        />
        <ActivityPageStatusCard
          title={t("singleActivityCompanyCard")}
          subTitle={company}
          bgColor={"#625ba8"}
        />
      </Box>
    </Box>
  );
}

export default ActivityHeader;
