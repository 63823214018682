import React, { useEffect, useState } from "react";
// import CountryCard from "../components/Cards/CityCard";
import ActivityCard from "../components/Cards/ActivityCard";
import CardCarouselSection from "../components/CardCarouselSection";
import { Box, Container, Typography } from "@mui/material";
// import ExplorePageHeader from "../components/ExplorePageHeader";
import FilterButton from "../components/FilterButton";
// custom hooks
import useFetchEvents from "../hooks/useFetchEvents";
import styles from "./ExplorePage.module.css";
import { useTranslation } from "react-i18next";
import Searchbar from "../components/Searchbar";
import { eventSearch, getEvents } from "../services/guest";
import useFetchCategories from "../hooks/useFetchCategories";
import useFetchCities from "../hooks/useFetchCities";
import CityCard from "../components/Cards/CityCard";
// import { current } from "@reduxjs/toolkit";
import LoadingCircle from "../components/LoadingCircle";
import useFetchEventDetails from "../hooks/useFetchEventDetails";

// react slick
// import CityCard from "../components/Cards/CityCard";

function ExplorePage() {
  const { cities } = useFetchCities();
  const { categories } = useFetchCategories();
  console.log("categories", categories);
  const currentLanguage = localStorage.getItem("language");

  const [isClicked, setIsClicked] = useState(true);
  const [filterClicked, setFilterClicked] = useState(false);

  const { events } = useFetchEvents();
  console.log("events", events);
  const { results } = useFetchEventDetails();
  console.log("events details", results);
  const [searchedEvents, setSearchedEvents] = useState(events);
  const [loading, setLoading] = useState(false);

  // set the initial filtered event with all the events
  useEffect(() => {
    if (events?.length) {
      setSearchedEvents(events);
    }
  }, [events]);

  // get the token for the activity card
  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token);
  console.log(tokenDecoded);
  //handle search functionality
  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const formattedQuery = query
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      console.log("search query", formattedQuery);
      const filtered = await eventSearch(formattedQuery);
      console.log("filtered search", filtered);
      setSearchedEvents(filtered);
    } catch (error) {
      console.error("Error searching events:", error);
    } finally {
      setLoading(false); // end loading
    }
  };
  // const { countries } = useFetchCountries();

  // scroll handler
  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.title = "YallaFalla | Explore";
  }, []);

  const { t } = useTranslation();

  const [activeFilter, setActiveFilter] = useState("");

  const handleFilter = (category) => {
    if (category === activeFilter) {
      setActiveFilter("");
      setSearchedEvents(events);
      setFilterClicked(!filterClicked);
    } else {
      setActiveFilter(category);
      const filtered = events.filter(
        (event) => event.category.toLowerCase() === category.toLowerCase()
      );
      setSearchedEvents(filtered);
      setIsClicked(false);
    }
  };

  const handlePopular = async () => {
    try {
      setActiveFilter("");
      const response = await getEvents();
      setSearchedEvents(response);
      setIsClicked(!isClicked);
      if (filterClicked) {
        setIsClicked(false);
      }
    } catch (error) {
      console.error("Error fetching popular events:", error);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        width: "100",
        marginBottom: "100px",
        padding: "0",
      }}
    >
      {/* header: location + notification */}
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          alignItems: "start",
        }}
      >
        {/* <ExplorePageHeader /> */}

        {/* search bar */}
        <Searchbar onSearch={handleSearch} />

        {/* filter */}
        <div className={styles.countryContainer} onWheel={handleScroll}>
          <Box>
            <button
              onClick={handlePopular}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                border: `1px solid #DD1515`,
                backgroundColor: isClicked ? "#DD1515" : "#fff",
                color: `${isClicked ? "white" : "#DD1515"}`,
                padding: "10px 20px",
                borderRadius: 5,
                fontSize: "14px",
                fontWeight: "100",
                textTransform: "uppercase",
              }}
            >
              {t("Popular")}
            </button>
          </Box>

          {categories.map((filter, index) => (
            <FilterButton
              key={filter.name}
              name={filter.name}
              text={filter.category_name}
              isSelected={
                !currentLanguage || currentLanguage === "en"
                  ? activeFilter === filter.name
                  : activeFilter === filter.category_name
              }
              onFilter={handleFilter}
              index={index}
            >
              {filter.icon}
            </FilterButton>
          ))}
        </div>
      </Box>

      {/* activity section */}
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CardCarouselSection
          title={t("activitySectionTitle")}
          link={"/activities"}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <LoadingCircle />
            </Box>
          ) : searchedEvents?.length < 1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#595959",
                  marginBottom: "10px",
                }}
              >
                {t("NoResultsFound")}
              </Typography>
            </Box>
          ) : (
            <div className={styles.countryContainer} onWheel={handleScroll}>
              {searchedEvents.map((event) => {
                return (
                  <div className={styles.countryItem} key={event.name}>
                    <ActivityCard
                      key={event.name}
                      id={event.name}
                      event_id={event.name}
                      title={event.title}
                      city={event.address_title}
                      country={event.country}
                      image={event.image}
                      locationUrl={event.google_maps_location}
                      avgRating={event.average_rating}
                      token={tokenDecoded}
                      isLiked={event.is_liked}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </CardCarouselSection>
      </Box>

      {/* top cities section */}
      <Box>
        <CardCarouselSection title={t("TopCities")} link={"/explore-city"}>
          <div className={styles.countryContainer} onWheel={handleScroll}>
            {cities.map((city) => (
              <div key={city.id} className={styles.countryItem}>
                <CityCard title={city.city_name} name={city.name} />
              </div>
            ))}
          </div>
        </CardCarouselSection>
      </Box>

      {/* countries */}
      {/* <Box>
        <CardCarouselSection title="Countries">
          <div className={styles.countryContainer} onWheel={handleScroll}>
            {countries.map((country) => (
              <div key={country.id} className={styles.countryItem}>
                <CountryCard title={country.name} />
              </div>
            ))}
          </div>
        </CardCarouselSection>
      </Box> */}
    </Container>
  );
}

export default ExplorePage;
