import { Box, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Searchbar from "../components/Searchbar";
import useFetchCities from "../hooks/useFetchCities";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getCities } from "../services/guest";
import CityCard from "../components/Cards/CityCard";

function ExploreCityPage() {
  const { cities } = useFetchCities();
  const { t } = useTranslation();
  const [filteredCity, setFilteredCity] = useState(cities);
  const [searchQuery, setSearchQuery] = useState("");

  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate();
  const goBackHome = () => {
    navigate(-1);
  };

  const handleSearchChange = async (city) => {
    setSearchQuery(city);
    try {
      const results = await getCities(city);
      setFilteredCity(results);
    } catch (error) {
      console.error("Error fetching data", error.message);
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredCity(cities);
    }
  }, [cities, searchQuery]);

  console.log("filtered cities",filteredCity);
  

  

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "10px", sm: "30px", md: "50px" },
        alignItems: "center",
        paddingBottom: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "20px 0px",
        }}
      >
        {/* <ExplorePageHeader /> */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            alignSelf={"start"}
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton aria-label="back" onClick={goBackHome}>
              {currentLanguage === "en" ? (
                <ChevronLeftIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <ChevronRightIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              )}
            </IconButton>
            {t("Cities")}
          </Typography>
        </Box>
        {/* search bar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Searchbar onSearch={handleSearchChange} searchType="city" />
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2,1fr)",
          gap: { xs: 2, sm: 3, md: 5 },
        }}
      >
        {filteredCity.map((city) => (
          <CityCard key={city.id} title={city.city_name} name={city.name} />
        ))}
      </Box>
    </Container>
  );
}

export default ExploreCityPage;
