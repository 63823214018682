import React from "react";
import styles from "./LoadingCircle.module.css"; // Import CSS module

const LoadingCircle = () => {
  return (
    <div>
      <svg viewBox="25 25 50 50" className={styles.svg}>
        <circle r="20" cy="50" cx="50" className={styles.circle}></circle>
      </svg>
    </div>
  );
};

export default LoadingCircle;
