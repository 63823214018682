import { useEffect, useState } from "react";
import { getBanners } from "../services/guest";

const useFetchBanners = () => {
    const [banners,setBanners] = useState([])

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const data = await getBanners()
        setBanners(data);
      } catch (error) {
        console.error("Error fetching banners", error);
      }
    };
    fetchBanners();
  },[]);
  return {banners}
};
export default useFetchBanners;