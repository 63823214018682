import { useEffect, useState } from "react";
import { getEventCategories } from "../services/guest";

const useFetchCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getEventCategories()
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };
    fetchCategories();
  }, []);
  return {categories}
};
export default useFetchCategories;
