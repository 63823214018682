import { useEffect, useState } from "react";
import { getOrders } from "../services/guest";

const useFetchPreviousOrders = () => {
  const [previousOrders, setPreviousOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

//   const user = useSelector((state) => state.user);
  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token);
  
  console.log("from fetch users", tokenDecoded);

  useEffect(() => {
    const fetchPreviousOrders = async () => {
      try {
        setLoading(true);
        if (tokenDecoded) {
          const data = await getOrders(tokenDecoded, "previous");
          setPreviousOrders(data);
          setLoading(false);
        }
      } catch (error) {
        console.error("error fetching previousorders", error);
        setError(error.message);
        setLoading(false);
      }
    };
    if (tokenDecoded) {
      fetchPreviousOrders();
    }
  }, [tokenDecoded]);
  return { previousOrders, loading, error };
};
export default useFetchPreviousOrders;
