import { createContext, useContext, useEffect, useState } from "react";
import i18n from "../utils/i18";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "ar"
  );

  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.setAttribute(
      "dir",
      language === "en" ? "ltr" : language === "ar" ? "rtl" : "ltr"
    );
    if (language === "en") {
      document.body.style.fontFamily = "'english-regular', sans-serif";
    } else if (language === "ar") {
      document.body.style.fontFamily = "'arabic', sans-serif";
    }
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
