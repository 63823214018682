import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BoardingScreen1 from "./BoardingScreen1";
import BoardingScreen2 from "./BoardingScreen2";
import BoardingScreen3 from "./BoardingScreen3";
import { Box, Button, Pagination } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import IntroductionPageLanguageSwitcher from "../components/IntroductionPageLanguageSwitcher";

const Introduction = () => {
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current path

  const pageTitles = ["boarding1", "boarding2", "boarding3"];
  const totalPages = pageTitles.length;

  // Check if user is logged in by looking for a token
  const isLoggedIn = !!localStorage.getItem("token");
  const isGuest = !!localStorage.getItem("guest");

  // Redirect to homepage if logged in or already a guest, but only when not on login/register paths
  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn); // Check if token is correctly set
    console.log("isGuest:", isGuest);

    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "ar");
    }

    if (
      (isLoggedIn || isGuest) &&
      !["/login", "/register"].includes(location.pathname)
    ) {
      navigate("/explore"); // Redirect to homepage or explore if user is logged in or guest
    }
  }, [isLoggedIn, isGuest, navigate, location.pathname]);

  // Function to handle the "Continue as Guest" logic
  const handleContinueAsGuest = () => {
    localStorage.setItem("guest", "true"); // Set guest status in localStorage
    navigate("/"); // Navigate to explore page or home page
  };

  const handleSkipButton = () => {
    localStorage.setItem("guest", "true");
    navigate("/");
  };

  const handleLogin = () => {
    localStorage.setItem("guest", "true"); // Set guest status in localStorage

    navigate("/login");
    localStorage.setItem("guest", "false"); // Set guest status in localStorage
  };

  const handleSignup = () => {
    localStorage.setItem("guest", "true"); // Set guest status in localStorage

    navigate("/register");
    localStorage.setItem("guest", "false"); // Set guest status in localStorage
  };

  const handlePageDisplay = () => {
    switch (page) {
      case 0:
        return <BoardingScreen1 />;
      case 1:
        return <BoardingScreen2 />;
      case 2:
        return <BoardingScreen3 />;
      default:
        return null;
    }
  };

  // Framer Motion animation variants
  const pageVariants = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.3 }}
        style={{
          position: "fixed", // To keep it always at the top-right, even during scroll
          top: 16, // You can adjust these values as needed
          right: 16,
          zIndex: 1000, // Ensures it's always on top
        }}
      >
        <IntroductionPageLanguageSwitcher />
      </motion.div>
      <AnimatePresence mode="wait">
        <motion.div
          key={page}
          variants={pageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
        >
          {handlePageDisplay()}
        </motion.div>
      </AnimatePresence>

      {/* Pagination Dots */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
          color="primary"
          size="small"
          variant="outlined"
          shape="rounded"
          hidePrevButton
          hideNextButton
          renderItem={(item) => (
            <Button
              sx={{
                width: 10,
                height: 10,
                minWidth: 0,
                padding: 0,
                backgroundColor: item.page === page + 1 ? "#F26456" : "#E0E0E0",
                borderRadius: "50%",
                margin: "0 5px",
              }}
              onClick={() => setPage(item.page - 1)}
            />
          )}
        />
      </Box>

      {/* Buttons */}
      {page < 2 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 3,
          }}
        >
          <Button
            size="large"
            variant="contained"
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              color: "white",
              width: "80%",
              "&:hover": {
                backgroundColor: "#F26456",
              },
              "&:active": {
                backgroundColor: "#F26456",
              },
              "&:focus": {
                backgroundColor: "#F26456",
              },
            }}
            onClick={() => setPage((currentPage) => currentPage + 1)}
          >
            {t("Next")}
          </Button>
          <Button
            sx={{
              color: "#F26456",
            }}
            onClick={handleSkipButton}
          >
            {t("Skip")}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 3,
          }}
        >
          <LoadingButton
            fullWidth
            size="large"
            onClick={handleLogin}
            // loading={loading}
            variant="contained"
            loadingPosition="start"
            startIcon={<LoginIcon />}
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              width: "80%",
            }}
          >
            <span>{t("Login")}</span>
          </LoadingButton>
          <Button
            onClick={handleSignup}
            size="large"
            variant="contained"
            sx={{
              backgroundColor: "transparent",
              borderRadius: "50px",
              color: "#F26456",
              width: "80%",
              borderColor: "#F26456",
            }}
          >
            {t("SignUp")}
          </Button>
          <Link
            style={{
              color: "#7E7E7E",
              textDecoration: "none",
              fontSize: "15px",
            }}
            to="/"
            onClick={handleContinueAsGuest}
          >
            {t("ContinueAsGuest")}
          </Link>
        </Box>
      )}
    </div>
  );
};

export default Introduction;
