import { Box, Card, CardContent, Rating, Typography } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
import React from "react";

function ActivityTestimonial({ customerName, feedback, rating, reviewTime }) {
  // const currentLanguage = localStorage.getItem("language");
  // const stars = 4; // Number of filled stars
  // const totalStars = 5; // Total number of stars
  console.log(rating);
  console.log("feedback", feedback);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        bgcolor: "#e7e7e7",
        padding: "16px",
        width: "90%",
      }}
    >
      <CardContent sx={{ padding: "0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {customerName}
          </Typography>
          <Typography variant="body2" sx={{ color: "grey" }}>
            {reviewTime}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Rating
            name="read-only"
            value={rating}
            readOnly
            precision={0.5}
            sx={{
              display: "flex",
            }}
          />
        </Box>

        <Typography variant="body2" sx={{ mt: 1 }}>
          {feedback}
          {/* <span style={{ color: "grey" }}>Show more</span> */}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ActivityTestimonial;
