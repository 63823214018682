import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { eventReview } from "../services/guest";
import useFetchPreviousOrders from "../hooks/useFetchPreviousOrders";
import { useTranslation } from "react-i18next";
import useFetchEventDetails from "../hooks/useFetchEventDetails";

function OrderReviewDialog({
  open,
  onClose,
  orderId,
  eventId,
  onReviewSubmit,
}) {
  const { previousOrders } = useFetchPreviousOrders();
  const order = previousOrders.find((o) => o.name === orderId);
  console.log("order", order);
  const { results } = useFetchEventDetails(eventId);
  console.log("order id from review page", orderId);

  const [feedback, setFeedback] = useState(order?.feedback);
  const [rating, setRating] = useState(order?.rating);
  const [feedbackImg, setFeedbackImg] = useState(order?.feedback_image);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [submittingReview, setSubmittingReview] = useState(false);

  useEffect(() => {
    if (order) {
      setFeedback(order.feedback || "");
      setRating(order.rating || 0);
      setFeedbackImg(order.feedback_image || "");
    }
  }, [order]); // This will run whenever `order` changes

  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token);

  const { t } = useTranslation();

  // Handle rating change
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  // Handle feedback text change
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  // Ensure Base64 string has correct padding (multiple of 4)
  const ensureBase64Padding = (base64String) => {
    while (base64String.length % 4 !== 0) {
      base64String += "=";
    }
    return base64String;
  };

  // Set feedback image after ensuring correct padding
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadingImage(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result.split(",")[1];
        base64String = ensureBase64Padding(base64String);
        setFeedbackImg(`data:image/jpeg;base64,${base64String}`);
        setUploadingImage(false);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle review submission
  const handleRating = async () => {
    setSubmittingReview(true); // Start submission state
    try {
      const response = await eventReview(
        orderId,
        feedback,
        rating,
        feedbackImg,
        tokenDecoded
      );
      if (response.status === "Success") {
        onClose(); // Close dialog after successful submission
        onReviewSubmit(); // Trigger any action needed after submission
      }
    } catch (error) {
      console.error("Error occurred while submitting review:", error.message);
    } finally {
      setSubmittingReview(false); // End submission state
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          margin: 0,
          width: "100%",
          height: "95%",
          maxHeight: "none",
          borderRadius: "20px 20px 0px 0px",
          position: "fixed",
          bottom: 0,
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: "100%",
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Event details with image and title */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
            alignItems: "center",
            padding: "20px 0px",
          }}
        >
          <Avatar
            src={results?.image}
            sx={{ width: "100px", height: "100px" }}
          />
          <Typography
            sx={{
              color: "#5B9BD3",
              fontSize: "24px",
              fontWeight: "semi-bold",
            }}
          >
            {results?.title}
          </Typography>
        </Box>

        {/* Upload image section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {t("UploadImage")}
          </Typography>
          <Button
            variant="contained"
            component="label"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f26456",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "14px",
              width: "50%",
              padding: "5px 0px",
              borderRadius: 10,
              "&:hover": {
                backgroundColor: "#fff",
                color: "#f26456",
              },
            }}
            disabled={uploadingImage}
          >
            {uploadingImage ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t(feedbackImg ? "Change Image" : "Upload")
            )}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
        </Box>

        {/* Display uploaded feedback image */}
        {feedbackImg && (
          <Box sx={{ padding: "0 16px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#f2f2f2",
              }}
            >
              <img
                src={
                  feedbackImg.startsWith("data:image")
                    ? feedbackImg
                    : `${feedbackImg}`
                }
                alt="Feedback"
                style={{ width: "70%", height: "50%", borderRadius: "10px" }}
              />
            </Box>
          </Box>
        )}

        {/* Rating and feedback section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            padding: "0 16px",
          }}
        >
          {/* Rating */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>{t("Ratings")}</Typography>
            <Rating
              size="large"
              value={rating}
              onChange={(event, newValue) => handleRatingChange(newValue)}
              sx={{
                "& .MuiRating-iconEmpty": {
                  color: "gold",
                },
              }}
            />
          </Box>

          {/* Feedback textarea */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("AddNotesAndComments")}
            </Typography>
            <textarea
              value={feedback}
              onChange={handleFeedbackChange}
              placeholder={t("WriteItHere")}
              style={{
                height: "100px",
                resize: "none",
                borderRadius: 10,
                padding: "10px",
              }}
            />
          </Box>
        </Box>

        {/* Submit review button */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
            paddingBottom: "50px",
          }}
        >
          <Button
            onClick={handleRating}
            variant="contained"
            sx={{
              backgroundColor: "#f26456",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "18px",
              width: "fit-content",
              padding: "5px 100px",
              borderRadius: 10,
              "&:hover": {
                backgroundColor: "#fff",
                color: "#f26456",
              },
            }}
            disabled={submittingReview}
          >
            {submittingReview ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t(order?.rating ? "Edit" : "Send")
            )}
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
}

export default OrderReviewDialog;
