import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SpecialOfferCard = ({ image, price, title,id }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const handleSpecialCardClick = () =>{
    navigate(`/special-offer/${id}`);
  }
  return (
    <div onClick={handleSpecialCardClick}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
          overflowX: "scroll",
          borderRadius: 5,
          width: 300, // Fixed width for the card
          height: 200, // Fixed height for the card
        }}
      >
        <img
          src={image}
          alt="special offer"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            border: 1,
            borderRadius: 30,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            bottom: 22,
            left: 0,
            right: 0,
            backgroundColor: "#FFB400",
            margin: "auto",
            width: "70%",
            borderRadius: "10px",
          }}
        >
        {/* title section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#5B9BD3",
              color: "#fff",
              padding: "10px 15px",
              borderRadius: "10px",
              minWidth:'70px'
            }}
          >
            <Typography
              sx={{ margin: 0, textAlign: "center", fontSize: "12px" }}
            >
              {title}
            </Typography>
          </Box>

          {/* price section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              padding: "5px 20px",
            }}
          >
            <Typography
              sx={{
                margin: 0,
                color: "#3E6E97",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {price}
              <span
                style={{
                  fontSize: "12px",
                  padding: "2px",
                }}
              >
                {t('SAR')}
              </span>
            </Typography>
          </Box>
        </Box>

      </Box>
    </div>
  );
};

export default SpecialOfferCard;
