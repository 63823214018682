import { Box, Container } from "@mui/material";
import React from "react";
import ActivityImageCarousel from "../components/activityComponents/ActivityImageCarousel";
import ActivityHeader from "../components/activityComponents/ActivityHeader";
import ActivityTestimonial from "../components/activityComponents/ActivityTestimonial";
import ActivityBookNowSection from "../components/activityComponents/ActivityBookNowSection";
import CardCarouselSection from "../components/CardCarouselSection";

function OrderDetailPage() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginBottom: "150px",
      }}
    >
      <ActivityImageCarousel />
      <ActivityHeader
        description={
          "Lorem ipsum dolor sit amet,consectetur adipiscin adipiscing elit"
        }
        location={"Jeddah , Obhur Al-Shamaliyah"}
        name={"Fishing Trip"}
        price={"210"}
        orderId={"1109273"}
      />
      <Box>
        <CardCarouselSection title={"Ratings & Reviews "} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <ActivityTestimonial />
          <ActivityTestimonial />
        </Box>
      </Box>

      <ActivityBookNowSection title={"RE ORDER"} />
    </Container>
  );
}

export default OrderDetailPage;
