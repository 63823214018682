import React, { useEffect, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/Language";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import SmartToyIcon from "@mui/icons-material/SmartToy"; // Robot icon
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BottomNavBar = () => {
  const [value, setValue] = React.useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const primaryColor = "#F15A4B"; // Color from your provided image
  const { t } = useTranslation();

  const iconStyle = { fontSize: 25 }; // Adjust the size as needed
  // const user = useSelector(
  //   (state) => state.user.customer_data?.message?.message
  // );
  // useEffect(() => {
  //   if (user) {
  //     setIsLoggedIn(true);
  //   }
  // }, [user]);
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);
  return (
    <Box sx={{ marginTop: "50px" }}>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: primaryColor,
          // paddingLeft: "10px",
          // paddingRight: "10px",
          paddingY: "5px",
        }}
        elevation={1}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            backgroundColor: primaryColor,
            display: "flex",
            justifyContent: "center",
            padding: "0px",
          }}
        >
          {/* home page */}
          <Link
            to={"/"}
            className={({ isActive }) => (isActive ? "active" : "")}
            style={{
              textDecoration: "none",
            }}
          >
            <BottomNavigationAction
              showLabel={true}
              label={t("Home")}
              icon={<HomeIcon sx={iconStyle} />}
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "column", // Stack icon and label vertically
                alignItems: "center", // Center align icon and label
                justifyContent: "center", // Vertically center
                paddingTop: "10px",
              }}
            />
          </Link>
          {/* explore page */}
          <Link
            to={"/explore"}
            className={({ isActive }) => (isActive ? "active" : "")}
            style={{
              textDecoration: "none",
            }}
          >
            <BottomNavigationAction
              showLabel={true}
              label={t("Explore")}
              icon={<LanguageIcon sx={iconStyle} />}
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "column", // Stack icon and label vertically
                alignItems: "center", // Center align icon and label
                justifyContent: "center", // Vertically center
                paddingTop: "10px",
              }}
            />
          </Link>
          {/* ai page */}
          {/* <Link>
            <BottomNavigationAction
              label="Robot"
              icon={<SmartToyIcon sx={iconStyle} />}
              sx={{ color: "white" }}
            />
          </Link> */}
          <Link
            to={isLoggedIn ? "/orders" : "/login"}
            style={{
              textDecoration: "none",
            }}
          >
            <BottomNavigationAction
              showLabel={true}
              label={t("Orders")}
              icon={<CalendarTodayIcon sx={iconStyle} />}
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "column", // Stack icon and label vertically
                alignItems: "center", // Center align icon and label
                justifyContent: "center", // Vertically center
                paddingTop: "10px",
              }}
            />
          </Link>
          <Link
            to={isLoggedIn ? "/logout" : "/login"}
            style={{
              textDecoration: "none",
            }}
          >
            <BottomNavigationAction
              showLabel={true}
              label={t("Profile")}
              icon={<AccountCircleIcon sx={iconStyle} />}
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "column", // Stack icon and label vertically
                alignItems: "center", // Center align icon and label
                justifyContent: "center", // Vertically center
                paddingTop: "10px",
              }}
            />
          </Link>
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default BottomNavBar;
