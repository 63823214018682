import { Box, Container } from "@mui/material";
import UserBar from "../components/UserBar";
import CardCarouselSection from "../components/CardCarouselSection";
import CategoryCard from "../components/Cards/CategoryCard";
import SpecialOfferCard from "../components/Cards/SpecialOfferCard";
import BottomNavBar from "../components/BottomNavBar";
import useFetchCategories from "../hooks/useFetchCategories";
import ImageCarousel from "../components/ImageCarousel";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useFetchEvents from "../hooks/useFetchEvents";
import styles from "./ExplorePage.module.css";
import { useTranslation } from "react-i18next";
import useFetchBanners from "../hooks/useFetchBanners";

const HomePage = () => {
  const { t } = useTranslation();
  const { categories } = useFetchCategories();
  const { events } = useFetchEvents(1);
  const { banners } = useFetchBanners();
  console.log(banners);
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token);
  console.log(user, tokenDecoded);
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    setCustomerName(localStorage.getItem("customer_name"));
  }, []);

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.title = "YallaFalla | Home";
  }, []);

  return (
    <Container
      style={{
        maxWidth: "2000px",
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
        margin: "0",
        backgroundColor: "",
        padding: "0",
      }}
    >
      <UserBar customerName={customerName} />
      {/* Advertisement Carousel */}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          overflowY: "none",
        }}
      >
        <ImageCarousel images={banners} />
      </Box>
      {/* <AdvertisementCarousel/> */}
      {/* Categories Carousel */}
      <CardCarouselSection title={t("Categories")}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            overflowY: "none",
            padding: "10px 20px",
          }}
        >
          {categories.map((category) => {
            return (
              <CategoryCard
                name={category.name}
                key={category.name}
                title={category.category_name}
                image={category.image}
              />
            );
          })}
        </div>
      </CardCarouselSection>
      {/* special offers categories */}
      <CardCarouselSection title={t("SpecialOffers")}>
        <div className={styles.countryContainer} onWheel={handleScroll}>
          {events.map((event) => (
            <SpecialOfferCard
              title={event.title}
              price={event.price}
              image={event.image}
              id={event.name}
              key={event.name}
            />
          ))}
        </div>
      </CardCarouselSection>
      {/* Bottom Nav Bar */}
      <BottomNavBar />
    </Container>
  );
};

export default HomePage;
