import React from 'react';

const SupportPage = () => {
  return (
    <div>
      <h1>Support Page</h1>
    </div>
  );
};

export default SupportPage;
