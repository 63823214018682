import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./ImageCarousel.module.css";
import { useNavigate } from "react-router-dom";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log(images);
  const htmlRef = useRef(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    htmlRef.current = document.documentElement;
  }, []);

  const transformValue = `translateX(-${currentIndex * 100}%)`;

  const navigate = useNavigate();
  const handleBannerNavigation = (link) => {
    const urlPath = new URL(link).pathname;
    console.log(urlPath);
    navigate(urlPath);
  };

  return (
    <div
      className={`${styles.carouselContainer} ${styles.ltrOnly}`}
      {...handlers}
    >
      <div
        className={styles.carouselImages}
        style={{ transform: transformValue }}
      >
        {images.map((image, index) => (
          <div
            className={styles.carouselImage}
            key={index}
            onClick={() => handleBannerNavigation(image.link)}
          >
            <img src={image.image} alt={`carousel-${index}`} />
            <div className={styles.carouselImagesText}>
              <h1
                style={{
                  fontSize: "1.5rem",
                  letterSpacing: ".1em",
                  color: "white",
                  WebkitTextStroke: "1px #F9BE26", // Text stroke
                  textShadow: "2px 2px 3px rgba(0, 0, 0, 0.5)", // Text shadow
                }}
              >
                {image.title}
              </h1>
              <p
                style={{
                  fontSize: "1.2rem",
                  color: "white",
                  letterSpacing: "2px",
                  // WebkitTextStroke: ".05rem black", // Text stroke
                  textShadow: "3px 3px 6px rgba(0, 0, 0, 0.8)", // Text shadow
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                  padding: "5px 10px", // Padding around the text
                  borderRadius: "5px", // Rounded corners for smoother design
                }}
              >
                {image.subtitle}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.dotContainer}>
        {images.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === currentIndex ? styles.active : ""
            }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
