import { Box, Typography } from "@mui/material";
import React from "react";
import vector2 from "../assets/Vector2.png";
import hotel from "../assets/Hotel Booking-cuate (1) 1.png";
import { useTranslation } from "react-i18next";

const BoardingScreen2 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "75vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", // Ensures no part of the image goes outside
      }}
    >
      {/* images container */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {/* First image (vector1) */}
        <Box
          component="img"
          src={vector2}
          alt="vector-img"
          sx={{
            position: "absolute",
            zIndex: 1, // Make sure vector1 is behind trip
            width: "100%", // Adjust the width as needed
            top: 0,
            left: 0,
            objectFit: "cover", // Ensures image fits without overflow
          }}
        />
        {/* Second image (trip) */}
        <Box
          component="img"
          src={hotel}
          alt="trip-img"
          sx={{
            position: "relative",
            zIndex: 2, // Make sure trip is in front of vector1
            width: "80%", // Adjust the width as needed
            top: "40%",
          }}
        />
      </Box>

      {/* Text below the images */}
      <Box
        sx={{
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
          width: "100%",
          maxWidth: "300px",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "start" }}>
          {t("BoardingScreen2Title")}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "start" }}>
          {t("BoardingScreen2Title")}
        </Typography>
      </Box>
    </Box>
  );
};

export default BoardingScreen2;
