import { Box, Typography } from "@mui/material";
import React from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
function BookingPagesHeader({ currentPage }) {
    const currentLanguage = localStorage.getItem("language");

  const navigation = useNavigate();

  const {t} = useTranslation();

  const handleBackClick = () => {
    navigation(-1);
  };
  return (
    <Box
      sx={{
        bgcolor: "#f26456",
        paddingX: "20px",
        paddingY: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: 2,
        }}
      >
        <div onClick={handleBackClick}>
          {currentLanguage === "en" ? (
            <ChevronLeftIcon
              sx={{
                fontSize: "35px",
                color: "white",
                cursor: "pointer",
                direction: currentLanguage === "ar" ? "rtl" : "ltr",
              }}
            />
          ) : (
            <ChevronRightIcon
              sx={{
                fontSize: "35px",
                color: "white",
                cursor: "pointer",
                direction: currentLanguage === "ar" ? "rtl" : "ltr",
              }}
            />
          )}
        </div>
        <Box
          sx={{
            marginBottom: "10px",
          }}
        >
          <h2
            style={{
              color: "white",
            }}
          >
            {t("OrderPageTitle")}
          </h2>
        </Box>
      </Box>
      {/* icons container */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {/* registration icons */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AppRegistrationIcon
            sx={{
              color: currentPage === "registration" ? "white" : "#c6c2c2",
            }}
          />
          <Typography
            color={currentPage === "registration" ? "white" : "#c6c2c2"}
          >
            {t("OrderRegistrationPageTitle")}
          </Typography>
        </Box>
        <ArrowRightAltIcon
          sx={{
            color: "white",
          }}
        />
        {/* booking icon */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CalendarMonthIcon
            sx={{
              color:
                currentPage === "booking-information" ? "white" : "#c6c2c2",
            }}
          />
          <Typography
            color={currentPage === "booking-information" ? "white" : "#c6c2c2"}
          >
            {t("OrderBookingPageTitle")}
          </Typography>
        </Box>
        <ArrowRightAltIcon
          sx={{
            color: "white",
          }}
        />
        {/* payment icon */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CreditCardIcon
            sx={{
              color: currentPage === "payment" ? "white" : "#c6c2c2",
            }}
          />
          <Typography color={currentPage === "payment" ? "white" : "#c6c2c2"}>
            {t("OrderPaymentPageTitle")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default BookingPagesHeader;
