import { useEffect, useState } from "react";
import { getEventDetails } from "../services/guest";

const useFetchSingleEventDetails = (eventId) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoading(true);
        const data = await getEventDetails(eventId);
        setEventDetails(data);
      } catch (error) {
        console.error("Error fetching event details", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchEventDetails();
  }, [eventId]);
  return { eventDetails,error,loading };
};
export default useFetchSingleEventDetails;
