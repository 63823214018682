import { Box, Container } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import styles from "./OrdersPage.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrdersPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to get the current path segment
   const getPathName = useCallback(() => {
     const path = location.pathname.split("/").pop();
     return path === "" || path === "orders" ? "upcoming" : path;
   }, [location.pathname]);

  // State for the active button
  const [active, setActive] = useState(getPathName);

  // Update the active state when location changes
  useEffect(() => {
    setActive(getPathName());
  }, [location,getPathName]);

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  const { t } = useTranslation();

  return (
    <Container>
      <Box className={styles.root}>
        <div className={styles.buttonGroup}>
          <button
            className={`${styles.button} ${
              active === "upcoming"
                ? styles.buttonUpcoming
                : styles.buttonNotActive
            }`}
            onClick={() => {
              setActive("upcoming");
              handleNavigation("upcoming");
            }}
          >
            {t("upcomingOrderButtons")}
          </button>
          <button
            className={`${styles.button} ${
              active === "previous"
                ? styles.buttonPrevious
                : styles.buttonNotActive
            }`}
            onClick={() => {
              setActive("previous");
              handleNavigation("previous");
            }}
          >
            {t("previousOrderButtons")}
          </button>
        </div>
      </Box>
      <Outlet />
    </Container>
  );
}

export default OrdersPage;
