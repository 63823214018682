import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { eventSearch } from "../services/guest";
import { useTranslation } from "react-i18next";
import AllActivitiesCard from "../components/Cards/AllActivitiesCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const AllActivitiesPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentLanguage = localStorage.getItem("language");

  const query =
    new URLSearchParams(location.search).get("query")?.toLowerCase() || "";
  const city =
    new URLSearchParams(location.search).get("city")?.toLowerCase() || "";
  console.log("cities", city);
  const category =
    new URLSearchParams(location.search).get("category")?.toLowerCase() || "";
  console.log(category);

  useEffect(() => {
    const getFilteredEvents = async () => {
      try {
        setIsLoading(true);
        // Call the API to search for events matching the query
        const response = await eventSearch(query, city, category);
        setFilteredEvents(response);
        console.log("response", response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getFilteredEvents();
  }, [category, city, query]);

  // handle loading state
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "white",
          color: "black",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        }}
      >
        <h1>{t("Loading")}...</h1>
      </div>
    );
  }

  // handle empty state
  if(filteredEvents.length < 1) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          backgroundColor: "white",
          color: "black",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        }}
      >
        <h1>{t("NoResultsFound")}</h1>
      </div>
    );
  }

  const goBackHome = () => {
    navigate(-1);
  };

  console.log(filteredEvents);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          padding: "20px  0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("activitySectionTitle")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)", // Two columns
          gap: "20px", // Space between cards
          padding: "10px 20px",
        }}
      >
        {filteredEvents.length < 1 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#595959",
                marginBottom: "10px",
              }}
            >
              Not Found
            </Typography>
          </Box>
        ) : (
          filteredEvents.map((event) => (
            <AllActivitiesCard
              key={event.id}
              date={event.date}
              title={event.title}
              image={event.image}
              id={event.name}
              width={120}
              imageBoxHeight={120}
              minCardHeight={100}
              price={event.price}
              avgRating={event.average_rating}
            />
          ))
        )}
      </Box>
    </div>
  );
};

export default AllActivitiesPage;
