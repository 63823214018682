import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardCarouselSection = ({ title, children, link }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{}}>
      {/* <h2>{title}</h2> */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "0px 20px",
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Typography variant="caption" sx={{ color: "black" }}>
          {link && (
            <Link style={{ textDecoration: "none", color: "black" }} to={link}>
              {t("SeeMore")}
            </Link>
          )}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "10px",
          padding: "10px 0px 10px 0px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

CardCarouselSection.propTypes = {};

export default CardCarouselSection;
