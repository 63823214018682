import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function CityCard({ title, imageUrl,name }) {
    const navigate = useNavigate();
    const handleCategoryClick = () => {
      navigate(`/activities?city=${name}`);
    };
    console.log(name)
  return (
    <Card
    onClick={handleCategoryClick}
      sx={{
        width: {xs:'150px',sm:'170px',md:'170px'},
        minHeight: "200px",
        borderRadius: 5,
        overflow: "hidden",
        boxShadow:
          "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ height: 200, overflow: "hidden" }}>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTy6Skoi8-AOg-AaPZ93m6ZJ4sErfnYXTHqw&s"
          alt={title}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}

      >
        <Typography
          sx={{ fontSize: "16px", fontWeight: "bold"}}
          align="center"
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CityCard;
