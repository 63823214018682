import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./ActivityImageCarousel.module.css";
import ShareIcon from "@mui/icons-material/Share";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Box, Snackbar } from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { eventLike } from "../../services/guest";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

const ActivityImageCarousel = ({
  images,
  onOpen,
  isLiked,
  event_id,
  orderId,
  noQr,
  eventName,
  eventPrice,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [alert, setAlert] = useState(false);
  const navigation = useNavigate();
  const htmlRef = useRef(null);

  const location = useLocation();
  console.log(location.pathname);
  const isPreviousOrders = location.pathname.includes("previous");
  console.log("is previous", isPreviousOrders);
  // handle back icon
  const handleBackClick = () => {
    navigation(-1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    htmlRef.current = document.documentElement;
  }, []);
  const transformValue = `translateX(-${currentIndex * 100}%)`;

  const handleShareClick = () => {
    const linkToCopy = window.location.href; // Adjust this if you need to copy a different link
    // Copy the link to the clipboard
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        // Show an alert when the link is copied
        setAlert(true);
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };
  const handleCloseSnackbar = () => {
    setAlert(false);
  };

  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token);
  console.log(tokenDecoded);

  // handle event like
  const [isLike, setIsLike] = useState(isLiked);
  const [isLoading, setIsLoading] = useState(false);
  console.log("is liked", isLike);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleLikeClick = async (e) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const response = await eventLike(event_id, tokenDecoded);
      console.log("is liked response", response);
      setIsLike(!isLike);
    } catch (error) {
      console.error("Error liking activity", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQrClick = () => {
    console.log("order id", orderId);
    navigation(`/qr-code`, {
      state: {
        orderId: orderId,
        event_name: eventName,
        event_price: eventPrice,
      },
    });
  };

  return (
    <div
      className={`${styles.carouselContainer} ${styles.ltrOnly}`}
      {...handlers}
    >
      <div
        className={styles.carouselImages}
        style={{ transform: transformValue }}
      >
        {images.map((image, index) => (
          <div className={styles.carouselImage} key={index}>
            <img src={image} alt={`carousel-${index}`} />
          </div>
        ))}
      </div>
      <div className={styles.dotContainer}>
        {images.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === currentIndex ? styles.active : ""
            }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
      {/* share and review icons */}
      <div className={styles.buttonsContainer}>
        {!noQr && (
          <div
            style={{
              border: "1px solid #F26456",
              display: "flex",
              alignItems: "center",
              borderRadius: "100%",
              padding: "7px 10px",
              background: "rgba(255, 253, 233, 0.8)",
            }}
            className={
              isPreviousOrders ? styles.qrIcon : styles.cancelPageQrIcon
            }
            onClick={handleQrClick}
          >
            <QrCodeScannerIcon
              sx={{
                color: "#F26456",
              }}
            />
          </div>
        )}

        {isPreviousOrders && (
          <div
            style={{
              display: "flex",
              gap: 4,
            }}
          >
            {/* review icon */}
            <div
              style={{
                border: "1px solid #F26456",
                display: "flex",
                alignItems: "center",
                borderRadius: "100%",
                padding: "7px 10px",
                background: "rgba(255, 253, 233, 0.8)",
              }}
              onClick={() => onOpen(true)}
            >
              <RateReviewIcon
                sx={{
                  color: "#F26456",
                }}
              />
            </div>
            {/* share icon */}
            <div
              onClick={handleShareClick}
              className={styles.shareIcon}
              style={{
                border: "1px solid #2c72f8",
                borderRadius: "50%",
                padding: "7px 10px",
                cursor: "pointer",
                background: "rgba(255, 253, 233, 0.8)",
              }}
            >
              <ShareIcon
                sx={{
                  color: "#2c72f8",
                }}
              />
              {/* alert */}
              <Snackbar
                open={alert}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert onClose={handleCloseSnackbar} severity="success">
                  Link copied successfully.
                </Alert>
              </Snackbar>
            </div>
          </div>
        )}
        {!isPreviousOrders && (
          <div
            onClick={handleShareClick}
            className={styles.shareIconAlone}
            style={{
              border: "1px solid #2c72f8",
              borderRadius: "50%",
              padding: "7px 10px",
              cursor: "pointer",
              background: "rgba(255, 253, 233, 0.8)",
            }}
          >
            <ShareIcon
              sx={{
                color: "#2c72f8",
              }}
            />
            {/* alert */}
            <Snackbar
              open={alert}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity="success">
                Link copied successfully.
              </Alert>
            </Snackbar>
          </div>
        )}
      </div>
      {/* back icon */}
      <div className={styles.arrowContainer}>
        <div className={styles.arrowIcon} onClick={handleBackClick}>
          <ArrowBackIosNewIcon
            sx={{
              cursor: "pointer",
              background: "rgba(255, 253, 233, 0.8)",
              padding: "5px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
      </div>
      {/* favorite icon */}
      <div className={styles.favoriteContainer}>
        <div onClick={handleLikeClick} className={styles.favoriteIcon}>
          {isLike ? (
            <Box
              sx={{
                padding: "2px",
                borderRadius: "50%",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Favorite
                sx={{
                  color: "#F26456", // Red color if liked
                }}
              />
            </Box>
          ) : (
            <FavoriteBorderOutlined
              sx={{
                color: "#757f8d", // Default color if not liked
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityImageCarousel;
