import { Box, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import UpcomingCard from "../components/ordersComponents/UpcomingCard";
import useFetchOrders from "../hooks/useFetchOrders";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OrdersUpcoming() {
  const { orders, loading, error } = useFetchOrders();
  console.log(orders);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "YallaFalla | Upcoming Orders";
  }, [orders]);

  // loading handler
  if (loading) {
    return <h1>{t("Loading")}</h1>;
  }

  // error handler
  if (error) {
    return (
      <Container sx={{ paddingY: "50px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <h1>{t("OrderPageErrorMessage")}</h1>
          <Button
            onClick={() => navigate("/explore")}
            sx={{
              backgroundColor: "#f26456",
              color: "white",
              borderRadius: 7,
              padding: "10px 70px",
              cursor: "pointer",
              border: "1px solid",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f26456",
                color: "white",
              },
            }}
          >
            {t("ExploreEvents")}
          </Button>
        </Box>
      </Container>
    );
  }

  // no upcoming orders
  if (orders.length <= 0) {
    return (
      <Container sx={{ paddingY: "50px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <h1>{t("NoUpcomingYet")}</h1>
          <Button
            onClick={() => navigate("/explore")}
            sx={{
              backgroundColor: "#f26456",
              color: "white",
              borderRadius: 7,
              padding: "10px 70px",
              cursor: "pointer",
              border: "1px solid",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f26456",
                color: "white",
              },
            }}
          >
            {t("ExploreEvents")}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ paddingY: "50px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {orders?.map((order) => (
          <Link
            key={order?.name}
            to={`/orders/upcoming/${order.activity_event}/${order.name}`}
            state={{ orderStatus: order?.status }}
            style={{
              textDecoration: "none",
            }}
          >
            <UpcomingCard
              key={order.name}
              image={order.image}
              name={order.event_title}
              city={order.city}
              companyName={order.company_name}
              country={order.country}
              date={order.date}
              id={order.name}
              price={order.total_price}
              time={order.time}
            />
          </Link>
        ))}
      </Box>
    </Container>
  );
}

export default OrdersUpcoming;
