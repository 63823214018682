import { Box, Container, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

import ActivityTestimonial from "../components/activityComponents/ActivityTestimonial";
import { useNavigate, useParams } from "react-router-dom";
import useFetchSingleEventDetails from "../hooks/useFetchSingleEventDetails";
import styles from "./SingleActivityPage.module.css";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RatingsReviews from "../components/RatingAndReview";

function ReviewInterfacePage() {
  const { activityId } = useParams();
  const { eventDetails } = useFetchSingleEventDetails(activityId);
  console.log("event details", eventDetails);

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate();

  const goBackHome = () => {
    navigate(-1);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const feedbacksPerPage = 3; // You can set how many feedbacks you want per page

  // Calculate paginated feedbacks
  const lastFeedbackIndex = currentPage * feedbacksPerPage;
  const firstFeedbackIndex = lastFeedbackIndex - feedbacksPerPage;
  const currentFeedbacks = eventDetails?.feedbacks.slice(
    firstFeedbackIndex,
    lastFeedbackIndex
  );

  // Total number of pages
  const totalPages = Math.ceil(
    eventDetails?.feedbacks.length / feedbacksPerPage
  );

  // Handlers for pagination
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Render dot indicators
  const renderDots = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index + 1 === currentPage ? styles.activeDot : ""
            }`}
            onClick={() => setCurrentPage(index + 1)}
          ></span>
        ))}
      </Box>
    );
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        paddingY: "50px",
      }}
    >
      {/* title with back button */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("RatingAndReviews")} ({eventDetails?.feedbacks?.length})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RatingsReviews
          ratingObject={eventDetails?.rating_summary}
          activityId={activityId}
        />
        <hr
          style={{
            border: "none", // Ensures no border is displayed
            width: "90%",
            height: "0", // Ensures the hr's height doesn't interfere
            borderTop: "1px solid rgba(139,139,139,50%)", // Creates a visible line
          }}
        />
      </Box>
      {/* snapshots */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {eventDetails?.feedbacks?.some(
          (feedback) => feedback.feedback_image
        ) && (
          <>
            <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("singleActivityPageSnapShotsTitle")}
            </Typography>
            <div className={styles.snapshotContainer} onWheel={handleScroll}>
              {eventDetails.feedbacks.map((feedback, index) =>
                feedback.feedback_image ? (
                  <div className={styles.snapshotItem} key={index}>
                    <img
                      className={styles.snapshotImage}
                      src={feedback.feedback_image}
                      alt="snapshot"
                    />
                  </div>
                ) : null
              )}
            </div>
          </>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {currentFeedbacks?.map((feedback, index) => (
          <ActivityTestimonial
            key={index}
            customerName={feedback.customer_name}
            feedback={feedback.feedback}
            rating={feedback.rating}
            reviewTime={feedback.formatted_review_time}
          />
        ))}

        {/* Arrow Pagination Controls */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <IconButton onClick={goToPreviousPage} disabled={currentPage === 1}>
            {currentLanguage === "en" ? (
              <ArrowBackIosIcon />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </IconButton>
          {renderDots()} {/* Dots for page indicators */}
          <IconButton
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            {currentLanguage === "en" ? (
              <ArrowForwardIosIcon />
            ) : (
              <ArrowBackIosIcon />
            )}
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}

export default ReviewInterfacePage;
