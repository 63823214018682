import {
  Box,
  Button,
  Container,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingPagesHeader from "../components/BookingPagesHeader";
import { format } from "date-fns";
import { bookEvent } from "../services/guest";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MessagePopup from "./BookingSuccessPopup";

function BookingPaymentPage() {
  const { t } = useTranslation();
  const [successPopupOpen, setSuccessPopupOpen] = useState(false); // State for success popup
  const [paymentOption, setPaymentOption] = useState(false);

  //get the current location
  const location = useLocation();
  const path = location.pathname;
  const currentPage = path.split("/").pop();
  console.log("current page:", currentPage);

  const params = useParams();
  const formData = location.state;
  const eventID = params.activityId;
  const dateSelected = format(formData.date_selected, "yyyy-MM-dd");
  const timeSelected = formData.time_selected;
  const ticketsSelected = formData.ticket_count;
  const totalPrice = formData.total_price;
  // const user = useSelector((state) => state.user);
  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token);

  console.log(
    "form data:",
    formData,
    "eventId:",
    eventID.toString(),
    "date:",
    dateSelected,
    "number of tickets:",
    ticketsSelected,
    "time Selected:",
    timeSelected,
    "token",
    tokenDecoded,
    "total price: ",
    totalPrice
  );
  // const { results } = useFetchEventDetails(eventID);

  const handlePayNowClick = async () => {
    try {
      const response = await bookEvent(
        eventID,
        dateSelected,
        timeSelected,
        ticketsSelected,
        tokenDecoded
      );
      console.log("booking successful", response);
      setSuccessPopupOpen(true);
    } catch (error) {
      console.error("Error booking event:", error);
    }
  };

  //close popup
  const navigate = useNavigate();
  const handleClosePopup = () => {
    setSuccessPopupOpen(false);
    navigate(`/orders`);
  };

  // translation
  return (
    <Box>
      <BookingPagesHeader currentPage={currentPage} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* payment method section */}
        <Box
          sx={{
            marginTop: "50px",
            border: "1px solid #d9d9d9",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              borderRadius: "10px 10px 0 0",
              padding: "10px",
              backgroundColor: "#5b9bd3",
            }}
          >
            <Typography
              sx={{
                color: "white",
              }}
            >
              {t("PaymentMethods")}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio required onClick={() => setPaymentOption(true)} />
              <Typography>{t("CashOnDelivery")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio disabled />
              <Typography color={"#757f8d"}>{t("VisaOrMasterCard")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio disabled />
              <Typography color={"#757f8d"}>{t("ApplePay")}</Typography>
            </Box>
          </Box>
        </Box>
        {/* coupon section */}
        <Box
          sx={{
            marginTop: "50px",
            border: "1px solid #d9d9d9",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              borderRadius: "10px 10px 0 0",
              padding: "10px",
              backgroundColor: "#5b9bd3",
            }}
          >
            <Typography
              sx={{
                color: "white",
              }}
            >
              {t("Coupon")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <TextField
              id="outlined-basic"
              label={t("AddCoupon")}
              variant="standard"
            />
            <Button
              sx={{
                backgroundColor: "#5b9bd3",
                color: "white",
                fontSize: "18px",
                padding: "5px 30px",
                borderRadius: 5,
              }}
            >
              {t("AddCoupon")}
            </Button>
          </Box>
        </Box>
        {/* pay button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            width: "100%",
            marginTop: "100px",
          }}
        >
          <Typography
            color={"#757f8d"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {t("Total")} <br />
            <span
              style={{
                color: "#f26456",
              }}
            >
              {totalPrice}
            </span>
          </Typography>
          <Button
            sx={{
              backgroundColor: "#f26456",
              color: "white",
              borderRadius: 7,
              padding: "10px 70px",
              cursor: "pointer",
              border: "1px solid",
            }}
            onClick={handlePayNowClick}
            disabled={!paymentOption}
          >
            {t("PayNow")}
          </Button>
        </Box>
      </Container>
      <MessagePopup
        open={successPopupOpen}
        handleClose={handleClosePopup}
        messageTitle={t("SuccessMessageTitle")}
        messageText={t("SuccessMessage")}
        icon={<CheckCircleIcon sx={{ fontSize: 60, color: "#7AC78E" }} />}
      />
    </Box>
  );
}

export default BookingPaymentPage;
