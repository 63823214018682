import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function ActivityBookNowSection({ title, handleCancelOrder, orderId, type }) {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const { t } = useTranslation();

  const [isRegistered, setIsRegistered] = useState(false);
  const firstTimeStatus = localStorage.getItem("first_time_booking");
  console.log("first time status :", firstTimeStatus);

  useEffect(() => {
    if (firstTimeStatus > 0) {
      setIsRegistered(true);
    }
  }, [firstTimeStatus]);

  console.log(location.pathname);
  console.log("param", param.activityId);

  //
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = localStorage.getItem("authToken");
  // const tokenDecoded = atob(token);
  console.log(token);
  // const user = useSelector(
  //   (state) => state.user.customer_data?.message?.message
  // );

  // Check if user is logged in or not to enable order button
  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);
  console.log(isLoggedIn);

  // Navigate to booking registration page if user is logged in, otherwise navigate to login page
  const handleOrderClick = () => {
    if (isLoggedIn) {
      if (isRegistered) {
        navigate(`/${param.activityId}/booking/booking-information`);
      } else {
        navigate(`/${param.activityId}/booking/registration`);
      }
    } else {
      navigate(`/login`, {
        state: {
          redirect: location.pathname,
        },
      });
    }
  };

  // button handler
  const buttonClickHandler = () => {
    type === 'cancel' ? handleCancelOrder(orderId) : handleOrderClick();
  };

  // translation 
  return (
    <Box
      sx={{
        backgroundColor: "#f26456",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        justifyContent: "center",
        padding: "20px 0",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
      }}
    >
      <Typography sx={{ color: "white", fontWeight: "bold" }}>
        {/* {t("OrderButtonMessage")} */}
      </Typography>
      <Button
        onClick={buttonClickHandler}
        variant="contained"
        sx={{
          backgroundColor: "#fff",
          color: "#f26456",
          fontWeight: "bold",
          fontSize: "18px",
          width: "fit-content",
          padding: "10px 100px",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: "#fff",
            color: "#f26456",
          },
        }}
      >
        {title}
      </Button>
    </Box>
  );
}

export default ActivityBookNowSection;
