import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";


const MessagePopup = ({ open, handleClose,messageTitle,messageText,icon,type }) => {
  // translation
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "20px", padding: "20px" } }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{icon}</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography
          variant="h5"
          sx={{
            color: type === "cancel" ? "#7AC78E" : "#7AC78E",
            fontWeight: "bold",
          }}
        >
          {messageTitle}
        </Typography>
        <Typography variant="body1" sx={{ margin: "10px 0" }}>
          {messageText}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: type === "cancel" ? "#7AC78E" : "#7AC78E",
            color: "white",
            fontSize: "18px",
            padding: "10px 30px",
            borderRadius: "10px",
          }}
        >
          {t("Done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessagePopup;
