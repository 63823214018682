import { Box } from "@mui/material";
import React from "react";

function FilterButton({ children, text, onFilter, isSelected, name, index }) {
  const currentLanguage = localStorage.getItem("language");

  const handleFilteredEvents = () => {
    if (!currentLanguage || currentLanguage === "en") {
      onFilter(name);
    } else if (currentLanguage === "ar") {
      onFilter(text);
    }
  };

  const getStyles = () => {
    let styles = {
      display: "flex",
      alignItems: "center",
      gap: 2,
      border: "1px solid",
      backgroundColor: "#fff",
      color: "#000",
      padding: "10px 20px",
      borderRadius: 5,
      fontSize: "14px",
      fontWeight: "100",
      textTransform: "uppercase",
    };

    // Conditional styles based on name
    const colors = [
      { borderColor: "#5B9BD3", backgroundColor: "#5B9BD3", color: "#FFFFFF" }, // Water
      { borderColor: "#FFC107", backgroundColor: "#FFC107", color: "#FFFFFF" }, // Example 3
      { borderColor: "#DD1515", backgroundColor: "#B81515", color: "#FFFFFF" }, // Fun
      { borderColor: "#4CAF50", backgroundColor: "#4CAF50", color: "#FFFFFF" }, // Example 4
      // Add more colors if needed
    ];

    // Use index to determine the style
    const colorStyle = colors[index % colors.length]; // Cycle through colors

    styles.borderColor = colorStyle.borderColor;
    styles.backgroundColor = isSelected
      ? colorStyle.backgroundColor
      : "transparent";
    styles.color = isSelected ? colorStyle.color : colorStyle.borderColor;
    return styles;
  };

  return (
    <Box>
      <button variant="text" onClick={handleFilteredEvents} style={getStyles()}>
        {children}
        {text}
      </button>
    </Box>
  );
}

export default FilterButton;
