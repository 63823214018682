import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationCityOutlined from "@mui/icons-material/LocationCityOutlined";

const MyFavouriteCard = ({
  image,
  name,
  city,
  country,
  companyName,
  price,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 2fr 1.2fr", // 3 columns layout
        gap: 1,
        border: "1px solid #c7c7c7",
        borderRadius: "20px",
        height: {
          xs: "140px",
          sm: "120px",
        },
        minWidth: "320px",
        overflow: "hidden", // Ensures nothing overflows from the card
      }}
    >
      {/* Image Column */}
      <Box
        component="img"
        src={image}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "20px 0 0 20px", // only left corners rounded
        }}
      />

      {/* Details Column */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {/* Event Name */}
        <Box>
          <Typography variant="subtitle1" fontWeight="600" color="#5b9bd3">
            {name}
          </Typography>
        </Box>

        {/* Location Info */}
        <Box sx={{
        }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap:1
            }}
          >
            <LocationOnIcon sx={{ color: "#757575", fontSize: "20px" }} />
            <Typography fontWeight={200} fontSize={"13px"} color={"#a49b9b"}>
              {country}, {city}
            </Typography>
          </Box>

          {/* Company Name */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LocationCityOutlined sx={{ color: "#757575", fontSize: "20px" }} />
            <Typography fontWeight={200} fontSize={"13px"} color={"#a49b9b"}>
              {companyName}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Price and Favorite Column */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          paddingY: "5px",
        }}
      >
        {/* Favorite Icon */}
        <IconButton aria-label="add to favorites">
          <FavoriteIcon sx={{ color: "red" }} />
        </IconButton>

        {/* Price */}
        <Typography fontSize={"14px"} fontWeight={"bold"} color={"#03557e"}>
          {price} {t("SAR")}
        </Typography>
      </Box>
    </Box>
  );
};

export default MyFavouriteCard;
