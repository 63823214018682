import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Typography } from "@mui/material";
import React from "react";
import { LocationCityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function UpcomingCard({
  id,
  name,
  country,
  city,
  date,
  time,
  companyName,
  image,
  price,
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        border: "1px solid #c7c7c7",
        borderRadius: "20px",
        height: "170px",
        minWidth: "320px", // Set a larger width for the card
      }}
    >
      <img
        src={image}
        alt=""
        style={{
          width: "30%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "20px",
        }}
      />
      {/* card content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          gap: 1,
          width: "100%",
          padding: "10px 0px",
        }}
      >
        {/* card header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* title / id  */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#5b9bd3",
                lineHeight: "1em",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#b5b5b5",
              }}
            >
              {id}
            </Typography>
          </Box>
          {/* price box */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "0 10px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              fontSize={"18px"}
              fontWeight={"bold"}
              color={"#03557e"}
            >
              {price} <span>{t("SAR")}</span>
            </Typography>
          </Box>
        </Box>

        {/* card data */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: 1,
          }}
        >
          {/* location */}
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <LocationOnIcon sx={{ color: "#757575", fontSize: "20px" }} />
            <Typography fontWeight={200} fontSize={"13px"} color={"#a49b9b"}>
              {country}, {city}
            </Typography>
          </Box>
          {/* date */}
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <CalendarMonthIcon sx={{ color: "#757575", fontSize: "20px" }} />
            <Typography fontWeight={200} fontSize={"13px"} color={"#a49b9b"}>
              {date}, {time}
            </Typography>
          </Box>
          {/* company */}
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <LocationCityOutlined sx={{ color: "#757575", fontSize: "20px" }} />
            <Typography fontWeight={200} fontSize={"13px"} color={"#a49b9b"}>
              {companyName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UpcomingCard;
