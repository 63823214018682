import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { receiveMessage } from "../../services/guest";
const NotificationsPage = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate();
  const goBackHome = () => {
    navigate(-1);
  };

  const handleNavigateOrders = () => {
    navigate("/orders/upcoming");
  };

  const handleNavigateChat = () => {
    navigate("/chat");
  };

  const [latestMessage, setLatestMessage] = useState("");
  const [latestMessageTime, setLatestMessageTime] = useState("");
  const receiverEmail = "ahmed208087@gmail.com"; // Replace with dynamic receiver logic
  const senderEmail = localStorage.getItem("email");

  const fetchLatestMessage = async () => {
    try {
      const response = await receiveMessage(senderEmail, receiverEmail);
      if (response && response.length > 0) {
        const latest = response[response.length - 1]; // Assuming messages are sorted by creation time
        setLatestMessage(latest.content);
        setLatestMessageTime(
          new Date(latest.creation).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching latest message:", error);
    }
  };

  useEffect(() => {
    fetchLatestMessage();
  }, []);

  return (
    <div>
      {/* title and navigation */}
      <Box
        sx={{
          width: "100%",
          padding: "20px  0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("Notifications")}
        </Typography>
      </Box>

      {/* orders and notifications */}
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {/* orders */}
        <Box onClick={handleNavigateOrders}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              borderTop: "1px solid rgba(102, 112, 128, 0.18)",
              borderBottom: "1px solid rgba(102, 112, 128, 0.18)",
              padding: "20px 20px",
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "#5B9BD3",
                padding: "10px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReceiptIcon
                sx={{
                  color: "#fff",
                  width: "24px",
                  height: "24px",
                }}
              />
            </IconButton>
            <Typography
              sx={{
                fontSize: "20px",
                paddingLeft: "15px",
              }}
            >
              {t("Orders")}
            </Typography>
          </Box>
        </Box>
        {/* message section title */}
        <Box
          sx={{
            paddingLeft: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {t("Messages")}
          </Typography>
        </Box>
        {/* messages */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            borderTop: "1px solid rgba(102, 112, 128, 0.18)",
            borderBottom: "1px solid rgba(102, 112, 128, 0.18)",
            padding: "20px 20px",
          }}
          onClick={handleNavigateChat}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {/* sender name and time */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <Avatar
                sx={{
                  width: "53",
                  height: "47",
                }}
                src={localStorage.getItem("customer_image")}
              />
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography>
                    {localStorage.getItem("customer_name")}
                  </Typography>
                  <Typography>{latestMessageTime || t("No time")}</Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#7E7E7E",
                  }}
                >
                  {latestMessage || ""}
                </Typography>
              </Box>
            </Box>
            {/* message view */}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default NotificationsPage;
