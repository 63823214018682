import { useEffect, useState } from "react";
import { getOrders } from "../services/guest";
import { useSelector } from "react-redux";

const useFetchOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("authToken");
  const tokenDecoded = atob(token)
  console.log('from fetch orders' ,user.customer_data?.message?.token);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        if (tokenDecoded) {
          const data = await getOrders(tokenDecoded, "Coming");
          setOrders(data);
          setLoading(false);
        }
      } catch (error) {
        console.error("error fetching orders", error);
        setError(error.message);
        setLoading(false);
      }
    };
    if (tokenDecoded) {
      fetchOrders();
    }
  }, [tokenDecoded]);
  return { orders, loading, error };
};
export default useFetchOrders;
