import { useEffect, useState } from "react"
import { getCountries } from "../services/guest"

const useFetchCountries = () =>{
    const [countries, setCountries] =useState([])

    useEffect(()=>{
        const fetchCountries = async () =>{
            try{
                const data = await getCountries()
                setCountries(data)
            }catch(error){
                console.error("Error fetching countries", error)
            }
        }
        fetchCountries()
    },[])
    return {countries}
}
export default useFetchCountries