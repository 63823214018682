import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "../assets/logo 06-2 1.png";
import { useTranslation } from "react-i18next";

const BoardingScreen3 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", // Ensures no part of the image goes outside
      }}
    >
      {/* Logo */}
      <Box
        component="img"
        src={logo}
        alt="logo-img"
        sx={{
          width: "100%", // Adjust width as needed
          maxWidth: "400px", // Ensure the logo doesn't exceed max width
        }}
      />

      {/* Text */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "300px", // Adjust the width of the text container
          textAlign: "center", // Align the text to the center
        }}
      >
        <Typography variant="body1">{t("BoardingScreen3Title")}</Typography>
      </Box>
    </Box>
  );
};

export default BoardingScreen3;
