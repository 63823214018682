import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import { QRCodeCanvas } from "qrcode.react";
import BadgeIcon from "@mui/icons-material/Badge";

const QrCodePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, event_name, event_price } = location.state;
  const currentLanguage = localStorage.getItem("language");
  const { t } = useTranslation();

  const goBackHome = () => {
    navigate(-1);
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          padding: "20px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t("Receipt")}
        </Typography>
      </Box>
      {/* QR code container */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          backgroundColor: "#F26456",
          padding: { xs: "20px", sm: "30px", md: "40px" },
          margin: { xs: "10px", sm: "20px", md: "30px" },
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "80%", sm: "60%", md: "40%" }, // Responsive width for the QR box
            maxWidth: "250px", // Maximum width limit
            backgroundColor: "#FFF",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          {/* QR Code */}
          <QRCodeCanvas value={orderId} size={150} />
        </Box>

        {/* Event Name */}
        <Typography color={"white"} variant="h6" sx={{ marginTop: "20px" }}>
          {event_name}
        </Typography>

        {/* Order ID with Icon */}
        <Typography
          color={"white"}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <BadgeIcon sx={{ color: "white" }} />
          {orderId}
        </Typography>

        {/* Dashed Line */}
        <hr
          style={{
            width: "100%",
            borderTop: "2px dashed #FFF",
          }}
        />

        {/* Total Price */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "10px",
          }}
        >
          <Typography color="white">{t("Total")}</Typography>
          <Typography color="white">
            {event_price} {t("SAR")}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default QrCodePage;
